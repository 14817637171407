import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

function SaveMom() {
  return (
    <div className="col-lg-12 p-0">
    <div className="main-slider position-relative">
         <div className="rt_line">
          <img alt="" src="assets/home/Element_17.webp" />
         </div>
         {/* <div className="lt_line">
         <img alt="" src="assets/home/Element_18.webp" />
         </div> */}
         <div className="social_icons">
           <ul>
              <li><a href="https://www.linkedin.com/company/jiovio?originalSubdomain=in"> <img alt="" src="assets/home/Icon_01.svg" /></a></li>
              <li><a href="https://www.instagram.com/jiovio_healthcare/"> <img alt="" src="assets/home/Icon_02.svg" /></a></li>
              <li><a href="https://www.facebook.com/jioviohealthcare/"> <img alt="" src="assets/home/Icon_03.svg" /></a></li>
              <li><a href="https://twitter.com/jio_vio"> <img alt="" src="assets/home/Icon_04.svg" /></a></li>
              <li><a href="https://www.youtube.com/channel/UCQHoHxfqc62-pAv9a4XTcJA/featured"> <img alt="" src="assets/home/Icon_05.svg" /></a></li>
           </ul>
        </div>
        <Carousel className="position-relative" fade interval={6000}>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-100.webp"}
                 alt="First slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum 2</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-101.webp"}
                 alt="Second slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum 3</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-102.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-103.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-104"
                 src={"assets/home/banner/Group-104.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-104"
                 src={"assets/home/banner/Group-105.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
        </Carousel>
    {/* <div className="btm_img_line">
    <img
    className="d-block w-100"
    src={"assets/home/img_01.webp"}
    alt="Third slide"
    />
    </div> */}
     </div>
     <div className="section app-feature d-flex h-100 pt-lg-5 pt-sm-5 pt-3 pb-lg-5 pb-sm-5 pb-3">
            <div className="lt_line_common savemom">
                <img alt="" src="assets/home/Element_18.webp" />
            </div>
               <div className="container justify-content-center align-self-center">
                  <div className="row">
                     <div className="col-lg-12 col-sm-12 col-12">
                        <div className="grid-content">
                           <h3 className="mb-lg-4 mb-sm-4 mb-2">SAVEMOM</h3>
                           <p><strong>Savemom Features and Application</strong></p>
                           <p className='text-justify'> Savemom application is designed and built for Rural Population. Generally urban population uses a smart phone and have adequate healthcare facilities, were in the rural population everyone may not be using smart phone, internet and may have limited healthcare facilities.</p>
                           <p className='text-justify'> Savemom application is an AI enabled IoT Kit with Telemedicine features. The AlloKit consists of AlloWear, AlloTricorder</p>
                           <p><strong>AlloWear</strong></p>
                           <p className='text-justify'> 
                           A custom designed smart wearable device (bangle/necklace) which continuously tracks activity, sleep cycle of the pregnant woman. AlloWear looks like a fashion jewelry and fitted with small, safe sensors for data recording. AlloWear comes with a customized battery, which last long for about 4-5 months. 
                           </p>
                           <p>Reminds to take medicine, checkup and that increases the interaction of patient</p>
                           <p><strong>AlloTricorder</strong></p>
                           <p className='text-justify'> 
                           AlloTricorder is a handheld portable screening device, which screens multiple vital signs of your health. 
                           </p>
                            <p className='text-justify'> 
                            AlloTricorder measures Heart Rate, Oxygen Saturation, Temperature, Respiration Rate, Blood Pressure, ECG and Blood Glucose level. The data collected is transferred to the cloud through Bluetooth connectivity. 
                            </p>
                            <p className='text-justify'>
                            AlloTricorder shall be used for regular, random health screening of pregnant women, general public at home, clinics and hospitals. 
                            </p>
                            <p className='text-justify'> 
                            Savemom application along with AlloKit shall provide a positive pregnancy experience for the pregnant women. Savemom application with its AI enabled feature supports the doctors identifying comorbid, high/ moderate risk pregnant women, there by close monitoring shall be extended to them. Regular screening and monitoring also ensures safe pregnancy and delivery, there by provides reduction in maternity related mortality rates. 
                            </p>
                           <p><strong>Digital Antenatal Care at Remote Villages</strong></p>
                           <p className='text-justify'> 
                           As we know, the healthcare facilities, resources are limited in villages and remote locations. Savemom application provides a greater support for Digital Antenatal Care at the doorstep of the pregnant women in these locations.
                           </p>
                           <p className='text-justify'> We have additional features for Digital Antenatal care for Rural Population. </p>
                           <p className='text-justify'>In rural setup the healthcare worker, nurse, matron shall be provided with a tablet containing savemom application, AlloTricorder -1 and the quantity of AlloWear required for monitoring the pregnant women in the location. </p>
                           <p><strong>Admin Portal</strong></p>
                           <p className='text-justify'>
                           The healthcare worker shall be provided with the admin controls, he/she can add the doctors in the PHC, who shall be monitoring the pregnant women remotely. The healthcare worker then adds the basic information of each pregnant women during the first visit. One allowear shall be handed over to each pregnant women on registration. The AlloTricorder shall be used by the healthcare worker to screen of vitals during each visit, the data is transferred to the cloud. The frequency of visit by the healthcare worker shall be determined by the doctors and the medical team. 
                             </p>
                           <p className='text-justify'> 
                           As the healthcare worker shall be visiting the pregnant women in their location on a monthly/fortnightly basis, any pregnant women requiring nutritional supplements, could be supported with the help of NGO’s and CSR initiatives. Adequate nutrition and care shall also reduce the malnutrition in the expectant mothers and children. 
                           </p>
                           <p><strong>Doctor Portal</strong></p>
                           <p className='text-justify'>
                           This is the AI integrated web application which segregates the patient data as high risk and low risk categories. The doctors shall review, monitor the health data of all the patients, connected to him/her.
                            </p>
                            <p className='text-justify'>
                            The doctors can access the data from PHC, on the move using their mobile/tablets. Doctors can do video consultation and provide medical advice as required to the patients. 
                            </p>
                            <p><strong>Cloud Doctor</strong></p>
                            <p className='text-justify'>Savemom application has the option to enroll specialist doctors for advanced care and consultation. The doctors at PHC would like to refer the high-risk patient for a consultation or further medical care could use this platform. Cloud doctor shall be provided with the admin control by the healthcare worker and can support in online consultation and medical care as required.</p>
                            <p><strong>Savemom for Rural Population</strong></p>
                            <p><strong>Key Differentiator</strong></p>
                            <p className='text-justify'>The healthcare devices, software applications available in the market are of supine type, and provides either screening services or software applications. </p>
                            <p className='text-justify'>Savemom application is a standalone type, which integrates the screening, web application and technology. Savemom there by provides a comprehensive care and services for all our Pregnant Women. We believe in ‘Healthy Mom Health Baby’ !!!</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
       </div>
  )
}

export default SaveMom