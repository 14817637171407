import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

function AlloHms() {
  return (
    <div className="col-lg-12 p-0">
    <div className="main-slider position-relative">
         <div className="rt_line allohms">
          <img alt="" src="assets/home/Element_17.webp" />
         </div>
         {/* <div className="lt_line">
         <img alt="" src="assets/home/Element_18.webp" />
         </div> */}
         <div className="social_icons">
           <ul>
              <li><a href="https://www.linkedin.com/company/jiovio?originalSubdomain=in"> <img alt="" src="assets/home/Icon_01.svg" /></a></li>
              <li><a href="https://www.instagram.com/jiovio_healthcare/"> <img alt="" src="assets/home/Icon_02.svg" /></a></li>
              <li><a href="https://www.facebook.com/jioviohealthcare/"> <img alt="" src="assets/home/Icon_03.svg" /></a></li>
              <li><a href="https://twitter.com/jio_vio"> <img alt="" src="assets/home/Icon_04.svg" /></a></li>
              <li><a href="https://www.youtube.com/channel/UCQHoHxfqc62-pAv9a4XTcJA/featured"> <img alt="" src="assets/home/Icon_05.svg" /></a></li>
           </ul>
        </div>
        <Carousel className="position-relative" fade interval={6000}>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-100.webp"}
                 alt="First slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum 2</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-101.webp"}
                 alt="Second slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum 3</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-102.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-103.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-104"
                 src={"assets/home/banner/Group-104.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-104"
                 src={"assets/home/banner/Group-105.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
        </Carousel>
    {/* <div className="btm_img_line">
    <img
    className="d-block w-100"
    src={"assets/home/img_01.webp"}
    alt="Third slide"
    />
    </div> */}
     </div>
     <div className="section app-feature d-flex h-100 pt-lg-5 pt-sm-5 pt-3 pb-lg-5 pb-sm-5 pb-3">
               <div className="lt_line_common allohms">
                  <img alt="" src="assets/home/Element_18.webp" />
               </div>
               <div className="container justify-content-center align-self-center">
                  <div className="row">
                     <div className="col-lg-12 col-sm-12 col-12">
                        <div className="grid-content">
                           <h3 className="mb-lg-4 mb-sm-4 mb-2">ALLOHMS </h3>
                           <p className='text-justify'>
                           The Ayushman Bharat Digital Mission (ABDM) aims to develop the backbone necessary to support the integrated digital health infrastructure of the country.
                            </p>
                            <p className='text-justify'>
                            ABDM will bridge the existing gap amongst different stakeholders of Healthcare ecosystem through digital highways.
                            </p>
                            <p className='text-justify'>
                            Presenting AlloHMS an advanced Hospital Management System built in accordance with ABDM and regulatory guidelines. 
                           </p>
                           <p><strong>AlloHMS unique features includes </strong></p>
                            <p className='text-justify'>
                            a) Responsive HMS  - can be used in Mobiles, Tablet and Desktop computers/Laptops
                            </p>
                            <p className='text-justify'>
                            b) HMS On the Go: Unlike the conventional HMS which are deployed in desktop computers and can be accessed only from those computers, AlloHMS can be accessed on the go, at home or while travelling as well
                            </p>
                            <p className='text-justify'>
                            c) AlloHMS is built in accordance to MCI and Ayushman Bharat regulations for EMR/EHR 
                            </p>
                            <p className='text-justify'>
                            d) AlloHMS can be subscribed as whole package and as well as modules as required for the hospitals
                            </p>
                    <ul>
                        <li>Our Modules and Packages includes </li>
                        <li>Patient Management Services</li>
                        <li>Pharmacy Management </li>
                        <li>Lab Management </li>
                        <li>Human Resource Management</li>
                        <li>Finance Management </li>
                        <li>Virtual Consultation, Telemedicine</li>
                        <li>Insurance</li>
                        <li>Digital Camp Management </li>
                        <li>Canteen Management </li>
                        <li>AlloHMS Advantages </li>
                        <li>AlloHMS provides Digital Solution to your hospital requirements </li>
                        <li>AlloHMS can be integrated with ERP/Existing software</li>
                        <li>AlloHMS data can be stored in Cloud or hospital server </li>
                        <li>AlloHMS developed in line with the Ayushman Bharat Digital Mission </li>
                        <li>AlloHMS available as Silver, Gold & Platinum packages.</li> 
                        <li>For more information and free trial contact us</li>
                    </ul>
                        </div>
                     </div>
                  </div>
               </div>
         </div>
    </div>
  )
}

export default AlloHms