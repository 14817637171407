import React from 'react';
import { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from 'react-router-dom';


function About() {
    const [posts, setPosts] = useState([]);
    useEffect(() => {
                axios.get('https://jsonplaceholder.typicode.com/posts').then((res)=>{
                setPosts(res.data);
                })
                // fetch('https://jsonplaceholder.typicode.com/posts')
                //   .then((res) => res.json())
                //   .then((data) => {
                //     setPosts(data);
                //   })
                .catch((err) => {
                console.log(err);
                });
        }, []);

        /* Slick slider */
        const teams = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
            };
const team_con = [
         {
         name : "Senthil Kumar M",
         img :"assets/about/Team_01.webp",
         destination: "He has 10+ years of technology engineering, management and business development experience. He is the Founder, CEO of Jiovio Healthcare. He is also a prolific technology inventor with over 10+ granted patents. Prior to his entrepreneurship journey, he works in fortune 500 companies Samsung and Qualcomm as a senior research engineer in the field of wireless technology and portal electronics devices. He has been recognized as a Technology explorer by Nasscom Technengage.  He travelled across the world to deliver keynote talks in major tech conferences. His exposure to international markets makes him a leading IoT technovator in India.",
         linkedinurl: "https://www.linkedin.com/in/senthilkumar-murugesan/",
         designation: "",
         conatctNo:"",
         emailId:""
         },
         {
         name : "Dhinesh Pandian",
         img :"assets/about/Team_02.webp",
         destination: "He has 7+ years of experience in Business Management, Marketing. He is a Founder, COO of Jiovio Healthcare. He is the leading security expert in the country. He is a serial entrepreneur prior to Jiovio healthcare he founded a successful startup on security fleet tracking. He is a core panel member of the National Cyber defence and research centre India. He has a strong relationship with bureaucrats and the skill to do business development with Government officials.",
         linkedinurl: "https://www.linkedin.com/in/dhineshpandian/",
         designation: "",
         conatctNo:"",
         emailId:"" 
         },
         {
         name : "Karthikeyan sellamuthu",
         img :"assets/about/Team_03.webp",
         destination: "Doctor Karthikeyan is a proven intensivist within intensive care medicine, engaging in very complex analyses to provide appropriate patient treatment. He is a visionary who can advance goals and grow within the dynamic needs of critical care medicine. He is also Driven to communicate well and establish a strong rapport with all patients He is our Cheif Medical Advisor helping us to clinically validation of point of care health innovation. He has an MD in Accident and Emergency Medicine and critical care. Currently, he has more than 15+ years of experience in Emergency Medicine. Current he is working in Meenakshi Mission hospital",
         linkedinurl: "",
         designation: "Cheif Medical Advisor",
         conatctNo:"+919442706000",
         emailId:"cmo@jiovio.com"
         }
];

     
        /* Slick slider */
    
        return (
            // <ul>
            // {posts.map((item) => (
            // <li key={item.id}>{item.title}</li>
            // ))}
            // </ul>
            <div className="col-lg-12 p-0">
            <div className="main-slider position-relative">
         <div className="rt_line">
          <img alt="" src="assets/home/Element_17.webp" />
         </div>
         {/* <div className="lt_line">
         <img alt="" src="assets/home/Element_18.webp" />
         </div> */}
         <div className="social_icons">
           <ul>
              <li><a href="https://www.linkedin.com/company/jiovio?originalSubdomain=in"> <img alt="" src="assets/home/Icon_01.svg" /></a></li>
              <li><a href="https://www.instagram.com/jiovio_healthcare/"> <img alt="" src="assets/home/Icon_02.svg" /></a></li>
              <li><a href="https://www.facebook.com/jioviohealthcare/"> <img alt="" src="assets/home/Icon_03.svg" /></a></li>
              <li><a href="https://twitter.com/jio_vio"> <img alt="" src="assets/home/Icon_04.svg" /></a></li>
              <li><a href="https://www.youtube.com/channel/UCQHoHxfqc62-pAv9a4XTcJA/featured"> <img alt="" src="assets/home/Icon_05.svg" /></a></li>
           </ul>
        </div>
        <Carousel className="position-relative" fade interval={6000}>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-100.webp"}
                 alt="First slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum 2</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-101.webp"}
                 alt="Second slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum 3</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-102.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-103.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-104"
                 src={"assets/home/banner/Group-104.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-104"
                 src={"assets/home/banner/Group-105.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
        </Carousel>
    {/* <div className="btm_img_line">
    <img
    className="d-block w-100"
    src={"assets/home/img_01.webp"}
    alt="Third slide"
    />
    </div> */}
     </div>
            <div className="section app-feature d-flex h-100 pt-lg-5 pt-sm-5 pt-3 pb-lg-5 pb-sm-5 pb-3">
               <div className="lt_line_common">
                  <img alt="" src="assets/home/Element_18.webp" />
               </div>
               <div className="container justify-content-center align-self-center">
                  <div className="row">
                     <div className="col-lg-12 col-sm-12 col-12">
                        <div className="grid-content">
                           <h3 className="mb-lg-4 mb-sm-4 mb-2">WHO ARE WE?</h3>
                            <p className='text-justify'>
                             Welcome to SaveMom Private Limited</p>
                             <p>
                             Savemom is a HealthCare-Tech Company based in Madurai, India. Savemom offers Digital and Virtual Maternity Care at the Doorstep of Pregnant Women. Savemom offers Positive Pregnancy Experience for all expectant mothers.
                            </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* <div className="section app-feature d-flex h-100 pt-lg-5 pt-sm-5 pt-3 pb-lg-5 pb-sm-5 pb-3">
               <div className="lt_line_common">
                  <img alt="" src="assets/home/Element_18.webp" />
               </div>
               <div className="container justify-content-center align-self-center">
                  <div className="row">
                     <div className="col-lg-12 col-sm-12 col-12">
                        <div className="grid-content">
                           <h3 className="mb-lg-4 mb-sm-4 mb-2">OUR MISSION</h3>
                           <p className='text-justify'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum read more.</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div> */}
            <div className="section app-feature d-flex h-100 pt-lg-5 pt-sm-5 pt-3 pb-lg-5 pb-sm-5 pb-3">
               <div className="lt_line_common">
                  <img alt="" src="assets/home/Element_18.webp" />
               </div>
               <div className="container justify-content-center align-self-center">
                  <div className="row">
                     <div className="col-lg-12 col-sm-12 col-12">
                        <div className="grid-content">
                           <h3 className="mb-lg-4 mb-sm-4 mb-2">OUR VISION</h3>
                           <p className='text-justify'>
                           Savemom was established by a team of young technocrats with a vision to
                           </p>
                           <ul>
                            <li>Provide a Safe, Virtual Maternity Care for all Pregnant Women </li>
                            <li>Provide Digital Ante Natal Care at the Doorstep of all Pregnant Women in Rural areas</li>
                            <li>Reduces the preventable Maternity related Mortality rate in both Urban and Rural Population</li>
                            <li>Create and strengthen a digital bond between the Parents and the Unborn Child </li>
                            <li>Provides Positive Pregnancy Experience.</li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
<div className="section position-relative grid_about ourteam pt-lg-4 pt-sm-4 pt-3">
    <div className="lt_line_common">
      <img alt="" src="assets/home/Element_18.webp" />
    </div>
   <div className="testimonials_bg testimonials_home team pt-lg-4 pt-sm-4 pt-3">
     <div className="container">
                  <div className="row ">
                     <div className="col-lg-12 col-sm-12 col-12">
                        <div className="grid-content">
                           <h3 className="mb-lg-2 mb-sm-2 mb-2">OUR TEAM</h3>
                        </div>
                     </div>
                     <div className="col-lg-12 col-sm-12 col-12">
                        <Slider {...teams}>
                           {team_con.map((user, index) => {
                           return (
                           <div key={index}>
                              <div className='grid_bx text-center'>
                                 <img alt={user.name} src={user.img} />
                                 <h5><strong>{user.name}</strong></h5>
                                 {/* <p>{user.designation}</p> */}
                                 {/* <div className='row mb-2'>
                                 <div className='col-lg-2 col-sm-2 col-3'>
                                 <Link to={user.linkedinurl} ><i className="fa fa-linkedin" aria-hidden="true"></i></Link>
                                 </div>
                                 <div className='col-lg-5 col-sm-5 col-4'>
                                  <i className="fa fa-envelope-o" aria-hidden="true"></i> {user.emailId}
                                 </div>
                                 <div className='col-lg-5 col-sm-5 col-4'>
                                 <i className="fa fa-phone" aria-hidden="true"></i> {user.conatctNo}
                                 </div>
                                 </div> */}
                                 <h4>{user.destination}</h4>
                              </div>
                           </div>
                           )})}
                        </Slider>
                     </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section app-feature d-flex h-100 pt-lg-5 pt-sm-5 pt-3 pb-lg-5 pb-sm-5 pb-3">
               <div className="lt_line_common lt_line8percentage">
                  <img alt="" src="assets/home/Element_18.webp" />
               </div>
               <div className="container justify-content-center align-self-center">
                  <div className="row">
                     <div className="col-lg-12 col-sm-12 col-12">
                        <div className="grid-content">
                           <h3 className="mb-lg-4 mb-sm-4 mb-2">OUR SUPPORTERS </h3>
                        </div>
                     </div>
                     <div className='col-lg-12 col-sm-12 col-12'>
                        <div className='our_supports'>
                           <img alt='our supports' className='w-100 mb-3' src='assets/about/Group_120.png' />
                           {/* <h5>Lorem ipsum</h5> */}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="section app-feature d-flex h-100 pt-lg-5 pt-sm-5 pt-3 pb-lg-5 pb-sm-5 pb-3">
               <div className="lt_line_common lt_line10percentage">
                  <img alt="" src="assets/home/Element_18.webp" />
               </div>
               <div className="container justify-content-center align-self-center">
                  <div className="row">
                     <div className="col-lg-12 col-sm-12 col-12">
                        <div className="grid-content">
                           <h3 className="mb-lg-4 mb-sm-4 mb-2">OUR ACCELERATORS</h3>
                        </div>
                     </div>
                     <div className='col-lg-12 col-sm-12 col-12'>
                        <div className='our_supports'>
                           <img alt='our supports' className='w-100 mb-3' src='assets/about/Group_121.png' />
                           {/* <h5>Lorem ipsum</h5> */}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="section app-feature d-flex h-100 pt-lg-5 pt-sm-5 pt-3 pb-lg-5 pb-sm-5 pb-3">
               <div className="lt_line_common">
                  <img alt="" src="assets/home/Element_18.webp" />
               </div>
               <div className="container justify-content-center align-self-center">
                  <div className="row">
                     <div className="col-lg-12 col-sm-12 col-12">
                        <div className="grid-content">
                           <h3 className="mb-lg-4 mb-sm-4 mb-2">JOIN OUR TEAM</h3>
                        </div>
                     </div>
                     <div className='col-lg-12 col-sm-12 col-12'>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi .</p>
                        <a href='#' className='btn btn-see-more'>See current openings</a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
);
}
export default About
