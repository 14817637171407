import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from 'axios';
import { Link } from 'react-router-dom';

function NewsDetails(props) {


const [news, setNews] = useState({});
useEffect(() => {
    const param=window.location.pathname.split('/');
    console.log(param[param.length-1], "")
    fetch("https://fatneedle.com/allobaby_admin/admin_api/api/news/")
      .then((res) => res.json())
      .then((data) => {setNews(data.filter(o=>o.id==param[param.length-1])[0]); console.log(data)});
 }, []);

  return (
        <div className="col-lg-12 p-0">
            <div className="main-slider position-relative">
         <div className="rt_line">
          <img alt="" src="../assets/home/Element_17.webp" />
         </div>
         {/* <div className="lt_line">
         <img alt="" src="assets/home/Element_18.webp" />
         </div> */}
         <div className="social_icons">
           <ul>
              <li><a href="https://www.linkedin.com/company/jiovio?originalSubdomain=in"> <img alt="" src="../assets/home/Icon_01.svg" /></a></li>
              <li><a href="https://www.instagram.com/jiovio_healthcare/"> <img alt="" src="../assets/home/Icon_02.svg" /></a></li>
              <li><a href="https://www.facebook.com/jioviohealthcare/"> <img alt="" src="../assets/home/Icon_03.svg" /></a></li>
              <li><a href="https://twitter.com/jio_vio"> <img alt="" src="../assets/home/Icon_04.svg" /></a></li>
              <li><a href="https://www.youtube.com/channel/UCQHoHxfqc62-pAv9a4XTcJA/featured"> <img alt="" src="../assets/home/Icon_05.svg" /></a></li>
           </ul>
        </div>
        <Carousel className="position-relative" fade interval={6000}>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"../assets/home/banner/Group-100.webp"}
                 alt="First slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum 2</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"../assets/home/banner/Group-101.webp"}
                 alt="Second slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum 3</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"../assets/home/banner/Group-102.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"../assets/home/banner/Group-103.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-104"
                 src={"../assets/home/banner/Group-104.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-104"
                 src={"../assets/home/banner/Group-105.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
        </Carousel>
    {/* <div className="btm_img_line">
    <img
    className="d-block w-100"
    src={"assets/home/img_01.webp"}
    alt="Third slide"
    />
    </div> */}
     </div>
            <div className="section app-feature d-flex h-100 pt-lg-5 pt-sm-5 pt-3 pb-lg-5 pb-sm-5 pb-3">
               <div className="lt_line_common">
                  <img alt="" src="../assets/home/Element_18.webp" />
               </div>
               <div className="container justify-content-center align-self-center">
                  <div className="row">
                     <div className="col-lg-12 col-sm-12 col-12">
                        <div className="grid-content">
                           <h3 className="mb-lg-4 mb-sm-4 mb-2">ALLOBABY NEWS</h3>
                           <p className='text-justify'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum <a className='read_more' href="#">read more</a>.</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="section news_bx position-relative grid_about">
               <div className="container">
                  <div className="row ">
                     <div className="col-lg-12 col-sm-12 col-12">
                    
                     </div>
                  </div>
               </div>
            </div>
            <div className="section position-relative grid_about">
    {/* <div className="lt_line_common">
      <img alt="" src="assets/home/Element_18.webp" />
    </div> */}
            <div className="testimonials_bg testimonials_home pt-3">
            <div className="container" id="news-c" >
            <div className="col-lg-12 col-sm-12 col-12">
            <div className="grid-content">
            <div className='row'>
            <div className='col-lg-10 col-sm-12 col-12'>
            <h3 className="mb-lg-2 mb-sm-2 mb-2">{news?.title_name} </h3>
            </div>
            </div>
            </div>
            </div>
            <div className="col-lg-10 col-sm-12 col-12" >
            <p dangerouslySetInnerHTML={{ __html: news.description }}></p>
            </div>
            </div>
            </div>
            <div className='img_right_news'>
              <img alt='' className='img_banner_news' src='../assets/news/news_banner.webp' />
            </div>
            </div>
            {/* <div className="section testimonials_bg position-relative grid_about">
               <div className="lt_line_common">
                  <img alt="" src="assets/home/Element_18.webp" />
               </div>
               
            <div className='img_right_news'>
              <img alt='' className='img_banner_news' src='assets/news/news_banner.webp' />
            </div>
            </div> */}
            <div className="section top_stories position-relative grid_about">
               <div className="lt_line_common">
                  <img alt="" src="../assets/home/Element_18.webp" />
               </div>
               <div className="container">
                  <div className="row ">
                     <div className="col-lg-12 col-sm-12 col-12">
                        <div className="grid-content">
                           <h3 className="mb-lg-2 mb-sm-2 mb-2">TOP STORIES</h3>
                        </div>
                     </div>
                     <div className="col-lg-12 col-sm-12 col-12">
                        
                     </div>
                  </div>
               </div>
            </div>
         </div>
  )
}

export default NewsDetails