import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify'
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Button } from 'bootstrap';
import axios from 'axios';


function Blog() {

//   const data = `<p>lorem <b>ipsum</b></p>`
//   const sanitizedData = () => ({
//     __html: DOMPurify.sanitize(data)
//   })

  // const descriptions = ''
  // const descriptions = description.substring(0, 4);

    /* Slick slider */
const blogs_ = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
};

// const [datas, setDatas] = useState([])

// useEffect(()=>{
//    axios.get('https://fatneedle.com/allobaby_admin/admin_api/api/blogs_list')
//    .then(
//       res => setDatas(res.data)
//       ).catch(err => console.log(err));
//    }, []);

//    const arry = datas.map((data, index)=>{
//       return(
//        <p key={index}>{data.title_name}</p>
//       )
//    });

const [bloglist, setBloglist] = useState(null);

//const setBloglist = description.substring(0, 4);



useEffect(()=>{
   fetch("https://fatneedle.com/allobaby_admin/admin_api/api/blogs_list").then((res) => res.json()).then((data) => {setBloglist(data); console.log(data);})
}, [])
/* Slick slider */
/* Featured Post */
const featured_post_list = {
   dots: false,
   infinite: false,
   arrows: true,
   speed: 500,
   slidesToShow: 3,
   slidesToScroll: 1,
   responsive: [
       {
         breakpoint: 1024,
         settings: {
           slidesToShow: 3,
           slidesToScroll: 1,
           infinite: true,
           dots: true
         }
       },
       {
         breakpoint: 600,
         settings: {
           slidesToShow: 1,
           slidesToScroll: 1,
           initialSlide: 1
         }
       },
       {
         breakpoint: 480,
         settings: {
           slidesToShow: 1,
           slidesToScroll: 1
         }
       }
     ]
};

const [featuredlist, setFeaturedlist] = useState(null);

useEffect(()=>{
  fetch("https://fatneedle.com/allobaby_admin/admin_api/api/blogs_list").then((res) => res.json()).then((data) => {setFeaturedlist(data); console.log(data);})
}, [])
/* Featured post */

/* Slick slider */
const popular_post = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

const[popular_post_list, setPopularlist] = useState();
useEffect(()=>{
   // const regex = /(<([^>]+)>)/ig;
   // const setPopularlist = popular_post_list.replace(regex, '');
   fetch("https://fatneedle.com/allobaby_admin/admin_api/api/trendinglist")
   .then((res) => res.json())
   .then((data) => {setPopularlist(data); console.log(data);})
}, [])
/* Slick slider */
  return (
    <div className="col-lg-12 p-0">
            <div className="main-slider position-relative">
         <div className="rt_line">
          <img alt="" src="assets/home/Element_17.webp" />
         </div>
         {/* <div className="lt_line">
         <img alt="" src="assets/home/Element_18.webp" />
         </div> */}
         <div className="social_icons">
           <ul>
              <li><a href="https://www.linkedin.com/company/jiovio?originalSubdomain=in"> <img alt="" src="assets/home/Icon_01.svg" /></a></li>
              <li><a href="https://www.instagram.com/jiovio_healthcare/"> <img alt="" src="assets/home/Icon_02.svg" /></a></li>
              <li><a href="https://www.facebook.com/jioviohealthcare/"> <img alt="" src="assets/home/Icon_03.svg" /></a></li>
              <li><a href="https://twitter.com/jio_vio"> <img alt="" src="assets/home/Icon_04.svg" /></a></li>
              <li><a href="https://www.youtube.com/channel/UCQHoHxfqc62-pAv9a4XTcJA/featured"> <img alt="" src="assets/home/Icon_05.svg" /></a></li>
           </ul>
        </div>
        <Carousel className="position-relative" fade interval={6000}>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-100.webp"}
                 alt="First slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum 2</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-101.webp"}
                 alt="Second slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum 3</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-102.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-103.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-104"
                 src={"assets/home/banner/Group-104.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-104"
                 src={"assets/home/banner/Group-105.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
        </Carousel>
    {/* <div className="btm_img_line">
    <img
    className="d-block w-100"
    src={"assets/home/img_01.webp"}
    alt="Third slide"
    />
    </div> */}
     </div>
            <div className="section app-feature d-flex h-100 pt-lg-5 pt-sm-5 pt-3 pb-lg-5 pb-sm-5 pb-3">
               <div className="lt_line_common">
                  <img alt="" src="assets/home/Element_18.webp" />
               </div>
               <div className="container justify-content-center align-self-center">
                  <div className="row">
                     <div className="col-lg-12 col-sm-12 col-12">
                        <div className="grid-content">
                           <h3 className="mb-lg-4 mb-sm-4 mb-2">BLOGS</h3>
                           {/* <p className='text-justify'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum <a className='read_more' href="#">read more</a>.</p> */}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="section blogs_bx position-relative grid_about top_stories">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-12 col-sm-12 col-12">
                       <Slider {...blogs_}>
                           {bloglist && bloglist .map((user, index) => {
                               return (
                                <div key={index}>
                                  <div className='grid_bx'>
                                       <img alt={user.title_name} src={"https://fatneedle.com/allobaby_admin/admin_api/public/uploads/blogimages/"+user.blog_thumbnail} className="mb-3" />
                                       <h5 className='text-left'><strong>{user.title_name.substring(0, 30)}...</strong></h5>
                                       <h6 className="mb-3 text-left">{user.date}</h6>
                                       <p ></p>
                                       <Link to={`/blogdetails/${user.blog_id}`} className='read_more text-right mb-2 mt-2' >View details</Link>
                                  </div>
                                </div>
                           )})}
                       </Slider>
                     </div>
                     <div className="col-lg-12 col-sm-12 col-12 text-center mt-lg-5 mt-sm-5 mt-3">
                        <Link to={`/bloglist`} className='btn btn-primary view_more'>View more</Link>
                     </div>
                  </div>
               </div>
            </div>
            <div className="section blogs_bx position-relative top_stories grid_about">
            {/* <div className="lt_line_common">
            <img alt="" src="assets/home/Element_18.webp" />
            </div> */}
            <div className="testimonials_bg testimonials_home pt-lg-4 pt-sm-4 pt-3">
            <div className="container">
            <div className="row">
            <div className="col-lg-12 col-sm-12 col-12">
            <div className="grid-content">
            <h3 className="mb-lg-2 mb-sm-2 mb-2">FEATURED BLOG </h3>
            </div>
            </div>
            <div className="col-lg-12 col-sm-12 col-12">
            <Slider {...featured_post_list}>
               {featuredlist && featuredlist .map((user, index) => {
                  {console.log(user.description)}
               return (
               <div key={index}>
                     <div className='grid_bx'>
                     <img alt={user.title_name} src={"https://fatneedle.com/allobaby_admin/admin_api/public/uploads/blogimages/"+user.blog_thumbnail} className="mb-3" />
                     <h4 className='text-left'>{user.title_name.substring(0, 30)}...</h4>
                     {/* <p dangerouslySetInnerHTML={{ __html: user.description }}></p> */}
                     {/* <p dangerouslySetInnerHTML={sanitizedData()}></p> */}
                     {/* <p>{user.description}</p> */}
                     <p dangerouslySetInnerHTML={{ __html: user.description }} />
                     <Link to={`/blogdetails/${user.blog_id}`} className='read_more text-right mb-2 mt-2' >View details</Link>
                     </div>
                  </div>
               )})}
            </Slider>
            </div>
            </div>
            </div>
            </div>
            {/* <div className='img_right_news'>
            <img alt='' className='img_banner_news' src='assets/news/news_banner.webp' />
            </div> */}
            </div>
            <div className="section blogs_bx popular_post top_stories  position-relative grid_about">
               <div className="lt_line_common">
                  <img alt="" src="assets/home/Element_18.webp" />
               </div>
               <div className="container">
                  <div className="row ">
                     <div className="col-lg-12 col-sm-12 col-12">
                        <div className="grid-content">
                           <h3 className="mb-lg-2 mb-sm-2 mb-2">TOP STORIES</h3>
                        </div>
                     </div>
                     <div className="col-lg-12 col-sm-12 col-12">
                        <Slider {...popular_post}>
                           {popular_post_list && popular_post_list .map((user, index) => {
                           return (
                            <div key={index}>
                               <div className='grid_bx'>
                                 <img alt={user.title_name} src={"https://fatneedle.com/allobaby_admin/admin_api/public/uploads/blogimages/"+user.blog_thumbnail} className="mb-3" />
                                 <h4 className='text-left'  >{user.title_name.substring(0, 30)}...</h4>
                                 {/* <p dangerouslySetInnerHTML={{ __html: user.description }} limit = {10}></p> */}
                                  <p dangerouslySetInnerHTML={{ __html: user.description }} />
                                  <Link to={`/blogdetails/${user.blog_id}`} className='read_more text-right mb-2 mt-2' >View details</Link>
                               </div>
                              </div>
                           )})}
                        </Slider>
                     </div>
                  </div>
               </div>
            </div>
        </div>
  )
}

export default Blog