import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from 'axios';
import { Link } from 'react-router-dom';

function News() {

const [newslist, setNewsList] = useState(null);

useEffect(() => {
    fetch("https://fatneedle.com/allobaby_admin/admin_api/api/news")
      .then((res) => res.json())
      .then((data) => {setNewsList(data); console.log(data)});
      
 }, []);
/* Slick slider */
const news_ = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
};
/* Slick slider */
/* Slick slider */
const [newslist_stories, setNewsListStories] = useState(null);

useEffect(() => {
    fetch("https://fatneedle.com/allobaby_admin/admin_api/api/popular_newslist")
      .then((res) => res.json())
      .then((data) => {setNewsListStories(data); console.log(data)});
      
 }, []);

const top_stories = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

/* Slick slider */
//news_feature
/* Feature slider start */
const [newslist_feature, setNewsFeature] = useState(null);

useEffect(() => {
    fetch("https://fatneedle.com/allobaby_admin/admin_api/api/popular_newslist")
      .then((res) => res.json())
      .then((data) => {setNewsFeature(data); console.log(data)});
      
 }, []);

const news_feature = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
/* Feature slider end */
  return (
        <div className="col-lg-12 p-0">
            <div className="main-slider position-relative">
         <div className="rt_line">
          <img alt="" src="assets/home/Element_17.webp" />
         </div>
         {/* <div className="lt_line">
         <img alt="" src="assets/home/Element_18.webp" />
         </div> */}
         <div className="social_icons">
           <ul>
              <li><a href="https://www.linkedin.com/company/jiovio?originalSubdomain=in"> <img alt="" src="assets/home/Icon_01.svg" /></a></li>
              <li><a href="https://www.instagram.com/jiovio_healthcare/"> <img alt="" src="assets/home/Icon_02.svg" /></a></li>
              <li><a href="https://www.facebook.com/jioviohealthcare/"> <img alt="" src="assets/home/Icon_03.svg" /></a></li>
              <li><a href="https://twitter.com/jio_vio"> <img alt="" src="assets/home/Icon_04.svg" /></a></li>
              <li><a href="https://www.youtube.com/channel/UCQHoHxfqc62-pAv9a4XTcJA/featured"> <img alt="" src="assets/home/Icon_05.svg" /></a></li>
           </ul>
        </div>
        <Carousel className="position-relative" fade interval={6000}>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-100.webp"}
                 alt="First slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum 2</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-101.webp"}
                 alt="Second slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum 3</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-102.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-103.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-104"
                 src={"assets/home/banner/Group-104.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-104"
                 src={"assets/home/banner/Group-105.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
        </Carousel>
    {/* <div className="btm_img_line">
    <img
    className="d-block w-100"
    src={"assets/home/img_01.webp"}
    alt="Third slide"
    />
    </div> */}
     </div>
            <div className="section app-feature d-flex h-100 pt-lg-5 pt-sm-5 pt-3 pb-lg-5 pb-sm-5 pb-3">
               <div className="lt_line_common">
                  <img alt="" src="assets/home/Element_18.webp" />
               </div>
               <div className="container justify-content-center align-self-center">
                  <div className="row">
                     <div className="col-lg-12 col-sm-12 col-12">
                        <div className="grid-content">
                           <h3 className="mb-lg-4 mb-sm-4 mb-2">ALLOBABY NEWS</h3>
                           {/* <p className='text-justify'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum <a className='read_more' href="#">read more</a>.</p> */}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="section news_bx position-relative grid_about">
               <div className="container">
                  <div className="row ">
                     <div className="col-lg-12 col-sm-12 col-12">
                        <Slider {...news_}>
                           {newslist && 
                           newslist.map((user, index) => {
                           return (
                              <div key={index}>
                              <div className='grid_bx text-center'>
                              <img alt={user.title_name} src={"https://fatneedle.com/allobaby_admin/admin_api/public/uploads/newsimages/" + user.news_image} className="mb-3" />
                              <h5 className='text-left'><strong>{user.title_name.substring(0, 30)}...</strong></h5>
                              <h6 className="mb-3 text-left">Date : {user.date}</h6>
                              <p dangerouslySetInnerHTML={{ __html: user.description }} limit = {10}></p>
                              <a target='_blank' href={user.btn_link} className='read_more'>View details</a>
                              </div>
                              </div>
                           )})}
                        </Slider>
                     </div>
                     <div className="col-lg-12 col-sm-12 col-12 text-center mt-lg-5 mt-sm-5 mt-3">
                        <Link to={`/newslist`} className='btn btn-primary view_more'>View more</Link>
                     </div>
                  </div>
               </div>
            </div>
            <div className="section news_bx position-relative grid_about pt-lg-5 pt-sm-5 pt-3">
              <div className="testimonials_bg testimonials_home">
                <div className="container">
                  <div className="row ">
                     <div className="col-lg-12 col-sm-12 col-12">
                        <div className="grid-content">
                          <h3 className="mb-lg-2 mb-sm-2 mb-2">FEATURED NEWS</h3>
                         </div>
                     </div>
                     <div className="col-lg-9 col-sm-9 col-12">
                     <Slider {...news_feature}>
                           {newslist_feature && 
                           newslist_feature.map((user, index) => {
                           return (
                              <div key={index}>
                              <div className='grid_bx text-center'>
                              <img alt={user.title_name} src={"https://fatneedle.com/allobaby_admin/admin_api/public/uploads/newsimages/" + user.news_image} className="mb-3" />
                              <h5 className='text-left'><strong>{user.title_name.substring(0, 30)}...</strong></h5>
                              <h6 className="mb-3 text-left">Date : {user.date}</h6>
                              <p dangerouslySetInnerHTML={{ __html: user.description }} limit = {10}></p>
                              <a target='_blank' href={user.btn_link} className='read_more'>View details</a>
                              </div>
                              </div>
                           )})}
                        </Slider>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum </p>
                        <button className='btn_news'>FULL ARTICLE</button> */}
                     </div>
                  </div>
               </div>
            </div>
            <div className='img_right_news'>
              <img alt='' className='img_banner_news' src='assets/news/news_banner.webp' />
            </div>
            </div>
            {/* <div className="section testimonials_bg position-relative grid_about">
               <div className="lt_line_common">
                  <img alt="" src="assets/home/Element_18.webp" />
               </div>
               
            <div className='img_right_news'>
              <img alt='' className='img_banner_news' src='assets/news/news_banner.webp' />
            </div>
            </div> */}
            <div className="section top_stories position-relative grid_about news_bx">
               <div className="lt_line_common">
                  <img alt="" src="assets/home/Element_18.webp" />
               </div>
               <div className="container">
                  <div className="row ">
                     <div className="col-lg-12 col-sm-12 col-12">
                        <div className="grid-content">
                           <h3 className="mb-lg-2 mb-sm-2 mb-2">TOP STORIES</h3>
                        </div>
                     </div>
                     <div className="col-lg-12 col-sm-12 col-12">
                        <Slider {...top_stories}>
                           {newslist_stories && newslist_stories .map((poplist, index) => {
                           return (
                           <div key={index}>
                           <div className='grid_bx text-center'>
                           <div className='card_bx'>
                           <img alt={poplist.title_name} src={"https://fatneedle.com/allobaby_admin/admin_api/public/uploads/newsimages/" + poplist.news_image} className="mb-3" />
                           <h5 className='text-left'><strong >{poplist.title_name.substring(0, 30)}...</strong></h5>
                           <h6 className="mb-3 text-left">Date : {poplist.date}</h6>
                           <p dangerouslySetInnerHTML={{ __html: poplist.description }} limit = {10}></p>
                           <a target='_blank' href={poplist.btn_link} className='read_more'>View details</a>
                           {/* <Link to={`/popularnewsdetails/${user.id}`} className='read_more' >View details</Link> */}
                           </div>
                           </div>

                              {/* <div className='grid_bx text-center'>
                                <div className='card_bx'>

                                </div>
                                 <p>{user.decscription_story}</p>
                              </div> */}
                           </div>
                           )})}
                        </Slider>
                     </div>
                  </div>
               </div>
            </div>
         </div>
  )
}

export default News