import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import {Button, Form } from "react-bootstrap";

function contactus() {
  return (
    <div className="col-lg-12 p-0">
    <div className="main-slider position-relative">
         <div className="rt_line">
          <img alt="" src="assets/home/Element_17.webp" />
         </div>
         {/* <div className="lt_line">
         <img alt="" src="assets/home/Element_18.webp" />
         </div> */}
         <div className="social_icons">
           <ul>
              <li><a href="https://www.linkedin.com/company/jiovio?originalSubdomain=in"> <img alt="" src="assets/home/Icon_01.svg" /></a></li>
              <li><a href="https://www.instagram.com/jiovio_healthcare/"> <img alt="" src="assets/home/Icon_02.svg" /></a></li>
              <li><a href="https://www.facebook.com/jioviohealthcare/"> <img alt="" src="assets/home/Icon_03.svg" /></a></li>
              <li><a href="https://twitter.com/jio_vio"> <img alt="" src="assets/home/Icon_04.svg" /></a></li>
              <li><a href="https://www.youtube.com/channel/UCQHoHxfqc62-pAv9a4XTcJA/featured"> <img alt="" src="assets/home/Icon_05.svg" /></a></li>
           </ul>
        </div>
        <Carousel className="position-relative" fade interval={6000}>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-100.webp"}
                 alt="First slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum 2</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-101.webp"}
                 alt="Second slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum 3</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-102.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-103.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-104"
                 src={"assets/home/banner/Group-104.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-104"
                 src={"assets/home/banner/Group-105.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
        </Carousel>
    {/* <div className="btm_img_line">
    <img
    className="d-block w-100"
    src={"assets/home/img_01.webp"}
    alt="Third slide"
    />
    </div> */}
     </div>
    <div className="section app-feature d-flex h-100 pt-lg-5 pt-sm-5 pt-3 pb-lg-5 pb-sm-5 pb-3">
       <div className="lt_line_common contact_line">
          <img alt="" src="assets/home/Element_18.webp" />
       </div>
       <div className="container justify-content-center align-self-center">
          <div className="row">
             <div className="col-lg-12 col-sm-12 col-12">
                <div className="grid-content">
                   <h3 className="mb-lg-4 mb-sm-4 mb-2">CONTACT US</h3>
                   <p className='text-justify'>
                    <div className='row'>
                    <div className='col-lg-6 col-sm-6 col-12'>
                    <div className='row'>
                            <div className='col-lg-12 col-sm-12 col-12'>
                                <div className='row mb-3'>
                                    <div className='col-lg-4 col-sm-4 col-12'>
                                      <p>Phone number</p>
                                    </div>
                                    <div className='col-lg-8 col-sm-8 col-12'>
                                      <p className='mb-1'>+91 8095207092</p>
                                      <p className='mb-1'>+91 8015005005</p>
                                      <p className='mb-1'>+91 7667878400</p>
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <div className='col-lg-4 col-sm-4 col-12'>
                                      <p>Lanline</p>
                                    </div>
                                    <div className='col-lg-8 col-sm-8 col-12'>
                                      <p className='mb-1'>04523500629</p>
                                      <p className='mb-1'>04523500630</p>
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <div className='col-lg-4 col-sm-4 col-12'>
                                      <p>Email ID</p>
                                    </div>
                                    <div className='col-lg-8 col-sm-8 col-12'>
                                      <p className='mb-1'>hello@jiovio.com</p>
                                      <p className='mb-1'>hr@jiovio.com</p>
                                    </div>
                                </div>
                            </div>
                          </div>
                          {/* <div className='row'>
                            <div className='col-lg-12 col-sm-12 col-12'>
                                <div className='row mb-3'>
                                    <div className='col-lg-4 col-sm-4 col-12'>
                                      <p>Madurai</p>
                                    </div>
                                    <div className='col-lg-8 col-sm-8 col-12'>
                                        <p className='mb-1'>38, First Floor, Arunachala Arcade,
                                        Tpk Road, Pasumalai, Madurai,
                                        Tamil Nadu - 625004
                                        </p>
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <div className='col-lg-4 col-sm-4 col-12'>
                                      <p>Bengaluru</p>
                                    </div>
                                    <div className='col-lg-8 col-sm-8 col-12'>
                                      <p className='mb-1'>Lower Ground Floor, DD3, Diamond District ISRO Colony, Domlur, Bengaluru, Karnataka - 560008</p>
                                     </div>
                                </div>
                                <div className='row mb-3'>
                                    <div className='col-lg-4 col-sm-4 col-12'>
                                      <p>Singapore</p>
                                    </div>
                                <div className='col-lg-8 col-sm-8 col-12'>
                                <p className='mb-1'>No: 8 Burn Road,
                                #12-05 Trivex,
                                Singapore 369977.
                                </p>
                                </div>
                                </div>
                            </div>
                          </div> */}
                     </div>
                     <div className='col-lg-6 col-sm-6 col-12'>
                     <Form>
                    <Form.Group className="mb-3" controlId="name.ControlInput1">
                    {/* <Form.Label>Name*</Form.Label> */}
                    <Form.Control type="text" placeholder="Name*" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="mobile.ControlInput1">
                    {/* <Form.Label>Mobile Number</Form.Label> */}
                    <Form.Control type="text" placeholder="Mobile Number" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="email.ControlInput1">
                    {/* <Form.Label>Email ID*</Form.Label> */}
                    <Form.Control type="email" placeholder="Email ID*" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="comments.ControlTextarea1">
                    {/* <Form.Label>Example textarea</Form.Label> */}
                    <Form.Control as="textarea" placeholder='Comments' rows={3} />
                    </Form.Group>
                    <Button className="cnt_submit">Submit</Button>
                    </Form>
                    </div>
                    </div>
                   </p>
                </div>
             </div>
          </div>
       </div>
     </div>
     {/* <div className="section app-feature contactinfo d-flex h-100 pt-lg-5 pt-sm-5 pt-3 pb-lg-5 pb-sm-5 pb-3">
               <div className="lt_line_common">
                  <img alt="" src="assets/home/Element_18.webp" />
               </div>
               <div className="container justify-content-center align-self-center">
                  <div className="row">
                     <div className="col-lg-12 col-sm-12 col-12">
                        <div className="grid-content">
                           <h3 className="mb-lg-4 mb-sm-4 mb-2">CONTACT DETAILS</h3>
                          <div className='row'>
                            <div className='col-lg-8 col-sm-8 col-12'>
                                <div className='row mb-3'>
                                    <div className='col-lg-4 col-sm-4 col-12'>
                                      <p>Phone number</p>
                                    </div>
                                    <div className='col-lg-8 col-sm-8 col-12'>
                                      <p className='mb-1'>+91 8095207092</p>
                                      <p className='mb-1'>+91 8015005005</p>
                                      <p className='mb-1'>+91 7667878400</p>
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <div className='col-lg-4 col-sm-4 col-12'>
                                      <p>Lanline</p>
                                    </div>
                                    <div className='col-lg-8 col-sm-8 col-12'>
                                      <p className='mb-1'>04523500629</p>
                                      <p className='mb-1'>04523500630</p>
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <div className='col-lg-4 col-sm-4 col-12'>
                                      <p>Email ID</p>
                                    </div>
                                    <div className='col-lg-8 col-sm-8 col-12'>
                                      <p className='mb-1'>hello@jiovio.com</p>
                                      <p className='mb-1'>hr@jiovio.com</p>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div> */}
            <div className="section app-feature contactinfo d-flex h-100 pt-lg-5 pt-sm-5 pt-3 pb-lg-5 pb-sm-5 pb-3">
               <div className="lt_line_common">
                  <img alt="" src="assets/home/Element_18.webp" />
               </div>
               <div className="container justify-content-center align-self-center">
                  <div className="row">
                     <div className="col-lg-12 col-sm-12 col-12">
                        <div className="grid-content">
                           <h3 className="mb-lg-4 mb-sm-4 mb-2">OFFICE ADDRESS</h3>
                          <div className='row'>
                            <div className='col-lg-8 col-sm-8 col-12'>
                                <div className='row mb-3'>
                                    <div className='col-lg-4 col-sm-4 col-12'>
                                      <p className='text-uppercase'>Madurai</p>
                                    </div>
                                    <div className='col-lg-8 col-sm-8 col-12'>
                                        <p className='mb-1'>38, First Floor, Arunachala Arcade,
                                        Tpk Road, Pasumalai, Madurai,
                                        Tamil Nadu - 625004
                                        </p>
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <div className='col-lg-4 col-sm-4 col-12'>
                                    <p className='text-uppercase'>Bengaluru</p>
                                    </div>
                                    <div className='col-lg-8 col-sm-8 col-12'>
                                      <p className='mb-1'>Lower Ground Floor, DD3, Diamond District ISRO Colony, Domlur, Bengaluru, Karnataka - 560008</p>
                                     </div>
                                </div>
                                <div className='row mb-3'>
                                    <div className='col-lg-4 col-sm-4 col-12'>
                                    <p className='text-uppercase'>Singapore</p>
                                    </div>
                                <div className='col-lg-8 col-sm-8 col-12'>
                                <p className='mb-1'>No: 8 Burn Road,
                                #12-05 Trivex,
                                Singapore 369977.
                                </p>
                                </div>
                                </div>
                            </div>
                          </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
    </div>
  )
}

export default contactus