import React, { useLayoutEffect }  from 'react';
import { useState } from 'react';
import '../../src/App.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";

 
//import { logo } from 'assets/Allobaby_Logo.svg';

function NavbarMenu() {

    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
    

    // const HomePage = () => 'Home';
    // const About = () => 'About';

const [show, setShow] = useState(false);
const showDropdown = (e)=>{
    setShow(!show);
}
const hideDropdown = e => {
    setShow(false);
}

/* Navbar */
    const [expanded, setExpanded] = useState(false);
    const setToggle = () => {
    console.log('toggle');
    setExpanded(true)
    }
/* Navbar */
//const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

/* Navbar sticky */
const [navsticky, setNavSticky] = useState(false);
function stickyNavbar(){
if (window.scrollY >= 100){
    setNavSticky(true);
} else {
    setNavSticky(false);
}
}
window.addEventListener("scroll", stickyNavbar)
/* Navbar sticky end */

  return (

    <div className='col-lg-12 p-0 nav_top_menu'>
    { <Navbar OnToggle={setToggle} collapseOnSelect expand="lg" bg="white" className={navsticky ? 'navbar_expand fixed-top' : 'navbar_expand'} variant="white">
       <Container>
        <Navbar.Brand eventKey="1" as={Link} to="/">
         <img alt="Allobaby" src={"assets/Allobaby_Logo.svg"} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {/* <Nav className="me-auto">
            <Nav.Link href="#features">Home</Nav.Link>
            <Nav.Link href="#pricing">About Us</Nav.Link>
            <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
          </Nav> */}
          <Nav className="justify-content-end flex-grow-1 pe-3">
            {/* {expanded && } */}
            <Nav.Link smooth spy eventKey="1" className={splitLocation[1] === "" ? "active" : ""} as={Link} to='/'>Home</Nav.Link>
            <Nav.Link smooth spy eventKey="2" className={splitLocation[1] === "about" ? "active" : ""} as={Link} to='/about'>About Us</Nav.Link>
            {/* <Nav.Link href="#">Our Offerings</Nav.Link> */}
            <NavDropdown  title="Our Offerings" 
            id="collasible-nav-dropdown" 
            show={show}
            onMouseEnter={showDropdown} 
            onMouseLeave={hideDropdown}>
              {/* <NavDropdown.Item href="#">Our Offerings</NavDropdown.Item> */}
              <NavDropdown.Item eventKey="3" className={splitLocation[1] === "our-app" ? "active" : ""} as={Link} to="/our-app">OUR APP</NavDropdown.Item>
              <NavDropdown.Item eventKey="4" className={splitLocation[1] === "product" ? "active" : ""} as={Link} to="/product">OUR PRODUCT</NavDropdown.Item>
              {/* <NavDropdown.Divider /> */}
              <NavDropdown.Item className={splitLocation[1] === "our-solutions" ? "active" : ""} as={Link} to="/our-solutions">OUR SOLUTIONS</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link eventKey="5" as={Link} className={splitLocation[1] === "ourcustomers" ? "active" : ""} to="/ourcustomers">Our Customers</Nav.Link>
            <Nav.Link eventKey="6" as={Link} className={splitLocation[1] === "news" ? "active" : ""} to="/news">News</Nav.Link>
            <Nav.Link eventKey="7" as={Link} className={splitLocation[1] === "blog" ? "active" : ""} to="/blog">Blog</Nav.Link>
            <Nav.Link eventKey="8" as={Link} className={splitLocation[1] === "contact" ? "active" : ""} to='/contact'>Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>}   
   </div>
   );
}

export default NavbarMenu;
