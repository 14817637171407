import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

function Product() {
  return (
    <div className="col-lg-12 p-0">
         <div className="main-slider position-relative">
         <div className="rt_line">
          <img alt="" src="assets/home/Element_17.webp" />
         </div>
         {/* <div className="lt_line">
         <img alt="" src="assets/home/Element_18.webp" />
         </div> */}
         <div className="social_icons">
           <ul>
              <li><a href="https://www.linkedin.com/company/jiovio?originalSubdomain=in"> <img alt="" src="assets/home/Icon_01.svg" /></a></li>
              <li><a href="https://www.instagram.com/jiovio_healthcare/"> <img alt="" src="assets/home/Icon_02.svg" /></a></li>
              <li><a href="https://www.facebook.com/jioviohealthcare/"> <img alt="" src="assets/home/Icon_03.svg" /></a></li>
              <li><a href="https://twitter.com/jio_vio"> <img alt="" src="assets/home/Icon_04.svg" /></a></li>
              <li><a href="https://www.youtube.com/channel/UCQHoHxfqc62-pAv9a4XTcJA/featured"> <img alt="" src="assets/home/Icon_05.svg" /></a></li>
           </ul>
        </div>
        <Carousel className="position-relative" fade interval={6000}>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-100.webp"}
                 alt="First slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum 2</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-101.webp"}
                 alt="Second slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum 3</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-102.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-103.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-104"
                 src={"assets/home/banner/Group-104.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-104"
                 src={"assets/home/banner/Group-105.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
        </Carousel>
    {/* <div className="btm_img_line">
    <img
    className="d-block w-100"
    src={"assets/home/img_01.webp"}
    alt="Third slide"
    />
    </div> */}
     </div>
     <div className="section app-feature d-flex h-100">
   <div className="right_side_img">
    <img alt="App Features" className="parent_img" src="assets/products/Group_126.png" />
   </div>
   <div className="container justify-content-center align-self-center">
   <div className="lt_line_common">
    <img alt="" src="assets/home/Element_18.webp" />
   </div>
    <div className="row">
      <div className="col-lg-6 col-sm-6 col-12">
         <div className="grid-content">
           <h3 className="mb-lg-4 mb-sm-4 mb-2"> ALLOTRICORDER</h3>
            <p>
            AlloTricorder is a handheld portable screening device, which screens multiple vital signs of your health. 
            </p>
            <p>
            AlloTricorder measures Heart Rate, Oxygen Saturation, Temperature, Respiration Rate, Blood Pressure, ECG and Blood Glucose level. The data collected is transferred to the cloud through Bluetooth.
            </p>
            <p>
            AlloTricorder shall be used for regular, random health screening of pregnant women, general public at home, clinics and hospitals.
            </p>
           </div>
        </div>
      <div className="col-lg-6 col-sm-6 col-12">
      </div>
    </div>
   </div>
</div>
<div className="section app-feature d-flex h-100">
   <div className="left_side_img">
    <img alt="App Features" className="parent_img" src="assets/products/Group_125.png" />
   </div>
   <div className="container justify-content-center align-self-center">
   <div className="lt_line_common right_line">
    <img alt="" src="assets/home/Element_18.webp" />
   </div>
    <div className="row">
    <div className="col-lg-6 col-sm-6 col-12">
      </div>
      <div className="col-lg-6 col-sm-6 col-12">
         <div className="grid-content">
            <h3 className="mb-lg-4 mb-sm-4 mb-2"> ALLOWEAR</h3>
            <p>
            A custom designed smart wearable device (bracelet/necklace) which continuously tracks activity, sleep cycle of the pregnant woman. AlloWear looks like a fashion jewellery and fitted with small, safe sensors for data recording. AlloWear comes with a customized battery, which last long for about 4-5 months. 
           </p>
           <p>
           Alerts: Reminds to take medicine, check-up and that increases the interaction of patient. The data captured is transferred to the application through Bluetooth.
           </p>
         </div>
      </div>
    </div>
   </div>
</div>
<div className="section app-feature d-flex h-100 pb-0">
   <div className="right_side_img">
    <img alt="App Features" className="parent_img" src="assets/products/Group_127.png" />
   </div>
   <div className="container justify-content-center align-self-center">
   <div className="lt_line_common">
    <img alt="" src="assets/home/Element_18.webp" />
   </div>
    <div className="row">
      <div className="col-lg-6 col-sm-6 col-12">
         <div className="grid-content">
            <h3 className="mb-lg-4 mb-sm-4 mb-2"> ALLOGATE</h3>
            <p>
            Contactless screening application equipped with multi vital sensors for essential screening of public 
            </p>
            <p>Ideally suited for corporates, hospitals, educational institutions, government departments and industries </p>
            <p><strong>AlloGate Features and Application, AlloGate features includes </strong></p>
            <p><strong>Temperature Screening </strong></p>
            <p>Contactless Temperature screening through sensors with medical grade accuracy </p>
         </div>
      </div>
      <div className="col-lg-6 col-sm-6 col-12">
      </div>
    </div>
   </div>
</div>
    <div className="section app-feature d-flex h-100 pt-0">
    <div className="container justify-content-center align-self-center">
    <div className="row">
    <div className="col-lg-12 col-sm-12 col-12">
    <p><strong>SpO2 Sensor</strong></p>
    <p>The SpO2 sensor checks the respiratory oxygen level in the blood of the individual.</p>
    <p><strong>Auto Sanitizer Dispenser</strong></p>
    <p>Equipped with auto sanitizer dispenser which dispenses sanitizer and also the dryer, keeps your hand liquid free. </p>
    <p><strong>Respiratory Rate</strong></p>
    <p>Respiration rate is measured through the sensor</p>
    <p><strong>Blood pressure and Heart Rate </strong></p>
    <p>The BP and Heart Bate is measured through NIBP methodology with medical grade accuracy </p>
    <p><strong>ECG </strong></p>
    <p>ECG is measured using the sensors provided in the gadget </p>
    <p><strong>Display: AlloGate provided with 7’’ tablet with the application</strong></p>
    <p>Mobile application</p>
    <p>AlloGate mobile application display relevant information and guide the users for quick Screening.</p>
    <ul>
    <li>Capture Face for identity (Attendance)</li>
    <li>Record body temperature</li>
    <li>Record SPO2 & BP, ECC, Respiratory rate</li>
    <li>Customized Alerts – Alerts anyone having High temperature, low SPO2</li>
    <li>Offline support and synchronization of the application </li>
    </ul>
    <p>We provide our Client’s with AlloGate Web Application as well for monitoring of their staff health data, attendance for day to day operations. </p>
    <p>Dashboard provides the complete report of daily summary of visitors and their vital details. </p>
    <p>It alerts the admin in case any staff with high temperature, Low Blood saturation and Respiratory Rate.</p>
    <p>Provide real time high risk visitor/staff alert </p>
    <p><strong>AlloGate is ideally used at public gathering places like </strong></p>
        <ul>
        <li>Hospitals</li>
        <li>Railway Station</li>
        <li>Airport</li>
        <li>School</li>
        <li>Shopping Mall</li>
        <li>Hotel</li>
        <li>Companies</li>
        <li>Educational Institution</li>
        <li>Shopping Mall</li>
        <li>Hotel</li>
        <li>Companies</li>
        <li>Educational Institution</li>
        </ul>
    </div>
    </div>
    </div>
    </div>
    </div>
  )
}

export default Product