import React from "react";
import YouTube, { YouTubeProps } from 'react-youtube';
import { useEffect, useState, useRef } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Button, Card } from "react-bootstrap";
//import SideBar from "../Components/SideBar";
const delay = 5;

function HomePage(){

   /* Show hide message */
   const [iosicondown, setIosIconDown] = useState(false);

    /* */
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    // const [messing, setMessing ] = useState();
    /* */

    /* Youtube videso */
    const onPlayerReady: YouTubeProps['onReady'] = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
      }
    
      const opts: YouTubeProps['opts'] = {
        height: '400',
        width: '680',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        },
      };

    /* Youtube videos */
    const [showcontent, setContent] = useState(1);

    const handleDiv = (e) =>{
        setContent(e);
      // setTimeout(() => {
      //    // Most recent value
      //    //alert(showcontent);
      //    setContent(e);
      // }, 5000);
    }

    useEffect(()=>{
      let unmounted=false;
      let invrl=setInterval(() => {
         if(!unmounted){
            setContent(curnt=>curnt%7+1);
         }
      }, 5000);
      return ()=>{
         unmounted = true;
         clearInterval(invrl);
      }
    },[])

   //  const setContent = (e) => {
   //    e.preventDefault();
   //    setTimeout(() => {
   //      // Most recent value
   //      alert(messageRef.current);
   //    }, 2000);
   //  };

    const testimonial = [
        {
        title : "Piya",
        img :"assets/home/Image_01.webp",
        content: "My favorite thing about the Allobaby program is the ability to track my weight and blood pressure at home and frequently; and knowing what is considered normal with the feedback the app provides. Then being able to take action, if need be, should any of those readings be abnormal that week.",
        author: "Sarah M., Director of Events",
        },
        {
        title : "Gows",
        img :"assets/home/Image_01.webp",
        content: "I would definitely look for a provider who has Allobaby in the future...I have two children already, so it is difficult to find time to see a doctor without a kid coming along. Allobaby offered more time in between appointments, which appealed to me.",
        author: "Sarah M., Director of Events",
        },
        {
        title : "Siva Aruna",
        img :"assets/home/Image_01.webp",
        content: "There are so many symptoms that you're not familiar with and you're not sure if it's normal or not. Allobaby gave me the confidence to know that my pregnancy was on track and there was nothing to be worried about.",
        author: "Sarah M., Director of Events",
        },
        {
        title : "Jothi Priya",
        img :"assets/home/Image_01.webp",
        content: "I like that I can monitor my weight and blood pressure for myself, especially my weight. Having that graph on the app that shows where my weight is and where it should make me so comfortable with my weekly weight gain!",
        author: "Sarah M., Director of Events",
        }
    ];

    let url="/selfscreening";

    return(
         <div className="col-lg-12 p-0">
         <div className="main-slider position-relative">
         <div className="rt_line">
          <img alt="" src="assets/home/Element_17.webp" />
         </div>
         {/* <div className="lt_line">
         <img alt="" src="assets/home/Element_18.webp" />
         </div> */}
         <div className="social_icons">
           <ul>
              <li><a href="https://www.linkedin.com/company/jiovio?originalSubdomain=in"> <img alt="" src="assets/home/Icon_01.svg" /></a></li>
              <li><a href="https://www.instagram.com/jiovio_healthcare/"> <img alt="" src="assets/home/Icon_02.svg" /></a></li>
              <li><a href="https://www.facebook.com/jioviohealthcare/"> <img alt="" src="assets/home/Icon_03.svg" /></a></li>
              <li><a href="https://twitter.com/jio_vio"> <img alt="" src="assets/home/Icon_04.svg" /></a></li>
              <li><a href="https://www.youtube.com/channel/UCQHoHxfqc62-pAv9a4XTcJA/featured"> <img alt="" src="assets/home/Icon_05.svg" /></a></li>
           </ul>
        </div>
        <Carousel className="position-relative" fade interval={6000}>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-100.webp"}
                 alt="First slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum 2</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-101.webp"}
                 alt="Second slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum 3</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-102.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-103.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-104"
                 src={"assets/home/banner/Group-104.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-104"
                 src={"assets/home/banner/Group-105.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
        </Carousel>
    {/* <div className="btm_img_line">
    <img
    className="d-block w-100"
    src={"assets/home/img_01.webp"}
    alt="Third slide"
    />
    </div> */}
     </div>
<div className="section app-feature d-flex h-100">
    <div className="right_side_img">
    <img alt="App Features" className="parent_img" src="assets/home/Element_09.svg" />
    <div className="grid_round" id="tab">
    <span><img alt="App Features" onClick={()=>handleDiv(1)} className= {showcontent===1? "nav-link child_img child1 active": "nav-link child_img child1"} src="assets/home/Icon_06.svg" /></span>
    <span><img alt="App Features" onClick={()=>handleDiv(2)} className= {showcontent===2? "nav-link child_img child2 active": "nav-link child_img child2"} src="assets/home/Icon_07.svg" /></span>
    <span><img alt="App Features" onClick={()=>handleDiv(3)} className= {showcontent===3? "nav-link child_img child3 active": "nav-link child_img child3"} src="assets/home/Icon_08.svg" /></span>
    <span><img alt="App Features" onClick={()=>handleDiv(4)} className= {showcontent===4? "nav-link child_img child4 active": "nav-link child_img child4"} src="assets/home/Icon_09.svg" /></span>
    <span><img alt="App Features" onClick={()=>handleDiv(5)} className= {showcontent===5? "nav-link child_img child5 active": "nav-link child_img child5"} src="assets/home/Icon_10.svg" /></span>
    <span><img alt="App Features" onClick={()=>handleDiv(6)} className= {showcontent===6? "nav-link child_img child6 active": "nav-link child_img child6"} src="assets/home/Icon_11.svg" /></span>
    <span><img alt="App Features" onClick={()=>handleDiv(7)} className= {showcontent===7? "nav-link child_img child7 active": "nav-link child_img child7"} src="assets/home/Icon_12.svg" /></span>
    </div>
   </div>
   <div className="container justify-content-center align-self-center">
   <div className="lt_line_common h_one">
     <img alt="" src="assets/home/Element_18.webp" />
   </div>
      <div className="row">
       <div className="col-lg-6 col-sm-6 col-12">
         <div className="grid-content tab-content" id="tabcontent">
            <h3 className="mb-lg-4 mb-sm-4 mb-2"><span>APP</span> FEATURES</h3>
            <div className={showcontent===1? "tab-pane fade show active d-lg-block d-sm-block d-none" : "tab-pane fade show"}>
            <h5>Self-Screening </h5>
            <p>AlloBaby has list of symptoms that are commonly associated with pregnancy, if the pregnant women are having any of the symptom, they can select the same. They can select normal if they don’t have any symptom and doing well. <Link to="/selfscreening" className="read_more">Read more</Link></p>
            </div>
            <div className={showcontent===2? "tab-pane fade show active" : "tab-pane fade show"}>
            <h5>AI Driven Early Risk Identification</h5>
            <p>Remotely monitor all patients with unique workflows based on patient's clinical and social risk, to tailor prenatal and postpartum care to individual patient needs.</p>
           </div>
           <div className={showcontent===3? "tab-pane fade show active" : "tab-pane fade show"}>
           <h5>EMR Integration  </h5>
           <p>Enable deeper coordination between Allobaby and the EMR with our set of EMR-integrated standard and advanced workflows, allowing you to do what you need to in the fewest clicks possible.</p>
           
           </div>
           <div className={showcontent===4? "tab-pane fade show active" : "tab-pane fade show"}>
           <h5>Appointment booking</h5>
           <p>Allobaby can detect elevated risk in between prenatal and postpartum appointments, and will alert your doctor, if necessary.</p>
           
           </div>
           <div className={showcontent===5? "tab-pane fade show active" : "tab-pane fade show"}>
           <h5>Awareness on Pregnancy</h5>
           <p>Intelligent end-to-end virtual care program to manage your maternal population remotely. Streamline your members' journey through prenatal and postpartum with a custom mobile app featuring daily educational content. Push out timely reminders and urgent information to shape better behaviors and engage members on a routine basis. </p>
           
           </div>
           <div className={showcontent===6? "tab-pane fade show active" : "tab-pane fade show"}>
           <h5>Interactive AlloBoT</h5>
           <p>Leverage our modules to remotely monitor members and bridge access to care issues. Enable providers to virtually manage pregnant members across risk strata through maternal health monitoring. Enable real-time data collection and exception-based monitoring for blood pressure and mental health.</p>
           
           </div>
           <div className={showcontent===7? "tab-pane fade show active" : "tab-pane fade show"}>
           <h5>Virtual Teleconsultation </h5>
           <p>Enable providers to virtually manage pregnant members across risk strata through maternal health monitoring. Enable real-time data collection and exception-based monitoring for blood pressure and mental health. </p>
           
           </div>
          </div>
        </div>
       <div className="col-lg-6 col-sm-6 col-12">
      </div>
      <div className="col-lg-12 col-sm-12 col-12 d-lg-none d-sm-none d-block">
      <Card className="mb-3">
      <Card.Body>
      <h5>Self-Screening </h5>
      <p>AlloBaby has list of symptoms that are commonly associated with pregnancy, if the pregnant women are having any of the symptom, they can select the same. They can select normal if they don’t have any symptom and doing well. </p>
      <p className="text-right"><Link to="/selfscreening" className="read_more">Read more</Link></p>
      </Card.Body>
      </Card>
      <Card className="mb-3">
      <Card.Body>
      <h5>AI Driven Early Risk Identification</h5>
      <p>Remotely monitor all patients with unique workflows based on patient's clinical and social risk, to tailor prenatal and postpartum care to individual patient needs. </p>
      {/* <a href="#" className="read_more"> read more.</a> */}
      </Card.Body>
      </Card>
      <Card className="mb-3">
      <Card.Body>
      <h5>EMR Integration  </h5>
      <p>Enable deeper coordination between Allobaby and the EMR with our set of EMR-integrated standard and advanced workflows, allowing you to do what you need to in the fewest clicks possible.</p>
      </Card.Body>
      </Card>
      <Card className="mb-3">
      <Card.Body>
      <h5>Appointment booking</h5>
      <p>Allobaby can detect elevated risk in between prenatal and postpartum appointments, and will alert your doctor, if necessary.</p>
       </Card.Body>
      </Card>
      <Card className="mb-3">
      <Card.Body>
      <h5>Awareness on Pregnancy</h5>
      <p>Intelligent end-to-end virtual care program to manage your maternal population remotely. Streamline your members' journey through prenatal and postpartum with a custom mobile app featuring daily educational content. Push out timely reminders and urgent information to shape better behaviors and engage members on a routine basis. </p>
      </Card.Body>
      </Card>
      <Card className="mb-3">
      <Card.Body>
      <h5>Interactive AlloBoT</h5>
      <p>Leverage our modules to remotely monitor members and bridge access to care issues. Enable providers to virtually manage pregnant members across risk strata through maternal health monitoring. Enable real-time data collection and exception-based monitoring for blood pressure and mental health.</p>
      </Card.Body>
      </Card>
      <Card className="mb-3">
      <Card.Body>
      <h5>Virtual Teleconsultation</h5>
      <p>Enable providers to virtually manage pregnant members across risk strata through maternal health monitoring. Enable real-time data collection and exception-based monitoring for blood pressure and mental health. </p>
      </Card.Body>
      </Card>
      </div>
    </div>
   </div>
  </div>
   <div className="section app-feature d-flex h-100">
   <div className="container">
   {/* <Button onClick={()=> setMessing(!messing)} className="btn btn-primary">{ messing ? 'Hide Message' : 'Show Message'}</Button>
   {
   messing ? <div className="">Test Message</div> : null 
   } */}

   <div className="row justify-content-center align-self-center">
   <div className="col-lg-12 col-sm-12 col-12">
   <YouTube videoId="NEEhV7iZHLA" opts={opts} onReady={onPlayerReady} />
   </div>
   </div>
   </div>
   </div>
   <div className="section app-feature d-flex h-100">
   <div className="lt_line_common h_two">
   <img alt="" src="assets/home/Element_18.webp" />
   </div>
  <div className="container">
   <div className="row justify-content-center align-self-center">
      <div className="col-lg-12 col-sm-12 col-12">
         <div className="grid-content">
            <h3 className="mb-lg-5 mb-sm-5 mb-2">OUR PRODUCTS</h3>
         </div>
      </div>
      <div className="col-lg-4 col-sm-4 col-12">
        <div className="grid_products puct1 text-center">
          <Link to="/product">
          <img alt="products" className="mb-lg-5 mb-sm-5 mb-3" src="assets/All_Gate_1.png" /> 
          <h5>Allo Gate</h5>
          </Link>
        </div>
      </div>
      <div className="col-lg-4 col-sm-4 col-12">
        <div className="grid_products puct2 text-center">
          <Link to="/product">
          <img alt="products" className="mb-lg-5 mb-sm-5 mb-3" src="assets/Allo_Tricorder.png" /> 
          <h5>Allo Tricorder</h5>
          </Link>
        </div>
      </div>
      <div className="col-lg-4 col-sm-4 col-12">
        <div className="grid_products puct3 text-center">
          <Link to="/product">
          <img alt="products" className="mb-lg-5 mb-sm-5 mb-3" src="assets/AlloWear.png" /> 
          <h5>AlloWear</h5>
          </Link>
        </div>
      </div>
     </div>
   </div>
</div>
<div className="section position-relative">
<div className="lt_line_common h_three">
    <img alt="" src="assets/home/Element_18.webp" />
</div>
<div className="testimonials_bg testimonials_home">
<div className="container">
   <div className="row ">
      <div className="col-lg-12 col-sm-12 col-12">
         <div className="grid-content">
            <h3 className="mb-lg-5 mb-sm-5 mb-2">TESTIMONIALS</h3>
         </div>
      </div>
      <div className="col-lg-12 col-sm-12 col-12">
      <div>
      <Carousel id="testimonials" className="testimonials" fade interval={6000}>
        {testimonial.map((c, index) => {
          return (
           <Carousel.Item key={index}>
            <div className="row">
                <div className="col-lg-3 col-sm-4 col-12">
                <div className="grid_testimonials_bgimg">
                <img
                className="d-block w-100"
                src={c.img}
                alt={c.content}
                />
                </div>
                </div>
                <div className="col-lg-9 col-sm-8 col-12">
                <Carousel.Caption>
                 {/* <h3>{c.title}</h3> */}
                 <p>{c.content}</p>
                 </Carousel.Caption>
                </div>
            </div>
           </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
      </div>
   </div>
   </div>
</div>
</div>
<div className="section bg-white position-relative">
<div className="lt_line_common h_four">
    <img alt="" src="assets/home/Element_18.webp" />
</div>
   <div className="container">
   <div className="row ">
      <div className="col-lg-6 col-sm-6 col-12">
         <div className="grid-dwn-img" style={{
        backgroundImage:
          "url(assets/home/Element_14.svg)"}}>
            <img alt="Download" src="assets/home/Image_02.svg" />
         </div>
      </div>
      <div className="col-lg-6 col-sm-6 col-12">
         <div className="grid-content">
            <h3 className="mb-lg-5 mb-sm-5 mb-2 text-center">DOWNLOAD</h3>
              <div className="text-center">
                <div className="logo_1">
                 <img alt="Download" src="assets/home/Element_18.svg" />
                  </div>
                   <div className="logo_2 pt-lg-4 pt-sm-4 pt-3">
                  {/* <Button onClick={()=> setMessing(!messing)} className="btn btn-primary">{ messing ? 'Hide Message' : 'Show Message'}</Button>
                  {
                  messing ? <div className="">Test Message</div> : null 
                  } */}
                <img alt="Download" src="assets/home/Icon_13.svg" style={{cursor:"pointer"}} onClick={()=> setIosIconDown(!iosicondown)} />
                <img alt="Download" src="assets/home/Element_17.svg" />
                 <a href="https://play.google.com/store/apps/details?id=com.jiovio.allobabyh"><img alt="Download" src="assets/home/Icon_14.svg" /></a>
                </div>
                 {
                  iosicondown ? <div className="mt-2"><strong>Coming Soon</strong></div> : null
                 }
            </div>
         </div>
       </div>
     </div>
   </div>
 </div>
</div>
    )
}

export default HomePage;