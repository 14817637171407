import './App.css';
import HomePage from './Components/home';
import NavbarMenu from './Components/navbar';
import FooterSection from './Components/footer';
import About from './Components/about';
import OurApp from './Components/our-app';
import Product from './Components/product';
import OurSolutions from './Components/oursolutions';
import Contactus from './Components/contactus';
import OurCustomers from './Components/ourcustomers';
import News from './Components/news';
import Blog from './Components/blog';
import SelfScreening from './Components/selfscreening';
import SaveMom from './Components/savemom';
import NewsDetails from './Components/newsdetails';
import PopularNewsDetails from './Components/popularnewsdetails';
import FeatureNewsDetails from './Components/feature_news';
import BlogDetails from './Components/blogdetails';
import BlogList from './Components/bloglist';
import AlloHms from './Components/allohms';
import NewsList from './Components/newslist';

import { BrowserRouter } from 'react-router-dom';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from 'react-router-dom';

function App() {
  return (
    <div className="allobaby_web">
      <div className='container-fluid'>
        <div className='row'>
          <NavbarMenu/>
        </div>
        <div className='row'>
          <Routes>
          <Route path="/" exact element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/about" element={<About />} />
          <Route path='/our-app' element={<OurApp/>}/>
          <Route path='/product' element={<Product/>}/>
          <Route path='/our-solutions' element={<OurSolutions/>}/>
          <Route path='/contact' element={<Contactus/>}/>
          <Route path='/ourcustomers' element={<OurCustomers/>}/>
          <Route path='/news' element={<News/>}/>
          <Route path='/blog' element={<Blog/>}/>
          <Route path='/selfscreening' element={<SelfScreening/>}/>
          <Route path='/savemom' element={<SaveMom/>}/>
          <Route path='/allohms' element={<AlloHms/>}/>
          <Route path='/newsdetails/:id' element={<NewsDetails/>}/>
          <Route path='/popularnewsdetails/:id' element={<PopularNewsDetails/>}/>
          <Route path='/feature_news/:id' element={<FeatureNewsDetails/>}/>
          <Route path='/blogdetails/:id' element={<BlogDetails/>} />
          <Route path='/bloglist' element={<BlogList/>} />
          <Route path='/newslist' element={<NewsList/>} />
          </Routes>
        </div>
        <div className='row'>
          <FooterSection/>
        </div>
      </div>
    </div>
  );
}

export default App;
