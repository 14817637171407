import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';

function OurSolutions() {
  return (
    <div className="col-lg-12 p-0">
    <div className="main-slider position-relative">
         <div className="rt_line">
          <img alt="" src="assets/home/Element_17.webp" />
         </div>
         {/* <div className="lt_line">
         <img alt="" src="assets/home/Element_18.webp" />
         </div> */}
         <div className="social_icons">
           <ul>
              <li><a href="https://www.linkedin.com/company/jiovio?originalSubdomain=in"> <img alt="" src="assets/home/Icon_01.svg" /></a></li>
              <li><a href="https://www.instagram.com/jiovio_healthcare/"> <img alt="" src="assets/home/Icon_02.svg" /></a></li>
              <li><a href="https://www.facebook.com/jioviohealthcare/"> <img alt="" src="assets/home/Icon_03.svg" /></a></li>
              <li><a href="https://twitter.com/jio_vio"> <img alt="" src="assets/home/Icon_04.svg" /></a></li>
              <li><a href="https://www.youtube.com/channel/UCQHoHxfqc62-pAv9a4XTcJA/featured"> <img alt="" src="assets/home/Icon_05.svg" /></a></li>
           </ul>
        </div>
        <Carousel className="position-relative" fade interval={6000}>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-100.webp"}
                 alt="First slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum 2</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-101.webp"}
                 alt="Second slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum 3</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-102.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-103.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-104"
                 src={"assets/home/banner/Group-104.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-104"
                 src={"assets/home/banner/Group-105.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
        </Carousel>
    {/* <div className="btm_img_line">
    <img
    className="d-block w-100"
    src={"assets/home/img_01.webp"}
    alt="Third slide"
    />
    </div> */}
     </div>
    <div className="section app-feature d-flex h-100 pt-lg-5 pt-sm-5 pt-3 pb-lg-5 pb-sm-5 pb-3">
       <div className="lt_line_common">
          <img alt="" src="assets/home/Element_18.webp" />
       </div>
       <div className="container justify-content-center align-self-center">
          <div className="row">
             <div className="col-lg-12 col-sm-12 col-12">
                <div className="grid-content">
                   <h3 className="mb-lg-4 mb-sm-4 mb-2">ALLOLAB</h3>
                   <p className='text-justify'>
                   Allolab is a platform designed for any type of patient. This app is used by healthcare workers to monitor the patients they care for. It facilitates the connection between the patientand the doctor. The vital signs of the patients collected by the healthcare workers are uploaded to this app for regular monitoring. This helps in early detection of high-risk patients before serious complications arise. Appointment requests to physicians from Allolab are transmitted to Allodoc with all updated vital data.
                   </p>
                </div>
             </div>
          </div>
       </div>
    </div>
    <div className="section app-feature d-flex h-100 pt-lg-5 pt-sm-5 pt-3 pb-lg-5 pb-sm-5 pb-3">
       <div className="lt_line_common">
          <img alt="" src="assets/home/Element_18.webp" />
       </div>
       <div className="container justify-content-center align-self-center">
          <div className="row">
             <div className="col-lg-12 col-sm-12 col-12">
                <div className="grid-content">
                   <h3 className="mb-lg-4 mb-sm-4 mb-2">ALLODOC</h3>
                   <p className='text-justify'>
                    Allodoc is a platform through which doctors can connect with patients. Doctors confirm appointments through this platform and prescribe medications. The appointment request is sent from Allolab to Allodoc. Through Allodoc, the patient's important information is sent to the doctors. Taking into account the patient's health condition, prescriptions for medicines are issued online. In emergencies, a video call is possible, and the patient's vital signs are displayed on the screen.
                   </p>
                </div>
             </div>
          </div>
       </div>
    </div>
    <div className="section app-feature d-flex h-100 pt-lg-5 pt-sm-5 pt-3 pb-lg-5 pb-sm-5 pb-3">
       <div className="lt_line_common">
          <img alt="" src="assets/home/Element_18.webp" />
       </div>
       <div className="container justify-content-center align-self-center">
          <div className="row">
             <div className="col-lg-12 col-sm-12 col-12">
                <div className="grid-content">
                   <h3 className="mb-lg-4 mb-sm-4 mb-2">ALLOHMS</h3>
                    <p className='text-justify'>
                     The Ayushman Bharat Digital Mission (ABDM) aims to develop the backbone necessary to support the integrated digital health infrastructure of the country.
                    </p>
                   <p className='text-right'><Link to="/allohms" className="read_more">Read more</Link></p>
                </div>
             </div>
          </div>
       </div>
    </div>
    <div className="section app-feature d-flex h-100 pt-lg-5 pt-sm-5 pt-3 pb-lg-5 pb-sm-5 pb-3">
       <div className="lt_line_common">
          <img alt="" src="assets/home/Element_18.webp" />
       </div>
       <div className="container justify-content-center align-self-center">
          <div className="row">
             <div className="col-lg-12 col-sm-12 col-12">
                    <div className="grid-content">
                        <h3 className="mb-lg-4 mb-sm-4 mb-2">SAVEMOM</h3>
                        <p><strong>Savemom Features and Application</strong></p>
                        <p className='text-justify'>
                        Savemom application is designed and built for Rural Population. Generally urban population uses a smart phone and have adequate healthcare facilities, were in the rural population everyone may not be using smart phone, internet and may have limited healthcare facilities. 
                        </p>
                        <p className='text-right'><Link to="/savemom" className="read_more">Read more</Link></p>
                    </div>
             </div>
          </div>
       </div>
    </div>
 </div>
  )
}

export default OurSolutions