import React from 'react';
import Carousel from 'react-bootstrap/Carousel';


function SelfScreening() {
  return (
    <div className="col-lg-12 p-0">
    <div className="main-slider position-relative">
         <div className="rt_line">
          <img alt="" src="assets/home/Element_17.webp" />
         </div>
         {/* <div className="lt_line">
         <img alt="" src="assets/home/Element_18.webp" />
         </div> */}
         <div className="social_icons">
           <ul>
              <li><a href="https://www.linkedin.com/company/jiovio?originalSubdomain=in"> <img alt="" src="assets/home/Icon_01.svg" /></a></li>
              <li><a href="https://www.instagram.com/jiovio_healthcare/"> <img alt="" src="assets/home/Icon_02.svg" /></a></li>
              <li><a href="https://www.facebook.com/jioviohealthcare/"> <img alt="" src="assets/home/Icon_03.svg" /></a></li>
              <li><a href="https://twitter.com/jio_vio"> <img alt="" src="assets/home/Icon_04.svg" /></a></li>
              <li><a href="https://www.youtube.com/channel/UCQHoHxfqc62-pAv9a4XTcJA/featured"> <img alt="" src="assets/home/Icon_05.svg" /></a></li>
           </ul>
        </div>
        <Carousel className="position-relative" fade interval={6000}>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-100.webp"}
                 alt="First slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum 2</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-101.webp"}
                 alt="Second slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum 3</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-102.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-103.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-104"
                 src={"assets/home/banner/Group-104.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-104"
                 src={"assets/home/banner/Group-105.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
        </Carousel>
    {/* <div className="btm_img_line">
    <img
    className="d-block w-100"
    src={"assets/home/img_01.webp"}
    alt="Third slide"
    />
    </div> */}
     </div>
     <div className="section app-feature d-flex h-100 pt-lg-5 pt-sm-5 pt-3 pb-lg-5 pb-sm-5 pb-3">
               <div className="lt_line_common selfscreening">
                  <img alt="" src="assets/home/Element_18.webp" />
               </div>
               <div className="container justify-content-center align-self-center">
                  <div className="row">
                     <div className="col-lg-12 col-sm-12 col-12">
                        <div className="grid-content">
                           <h3 className="mb-lg-4 mb-sm-4 mb-2">Self screening </h3>
                           <p className='text-justify'>
                           AlloBaby has list of symptoms that are commonly associated with pregnancy, if the pregnant women are having any of the symptom, they can select the same. They can select normal if they don’t have any symptom and doing well. 
                            </p>
                            <p className='text-justify'>
                            Then they will find the list of vitals Blood pressure, Temperature, Blood saturation, Heart rate, Blood Glucose, BMI, Respiratory rate and HRV. They can select the readings based on their data from the gadgets they use for testing. 
                            </p>
                            <p className='text-justify'>
                            The entered data shall be available in the pregnant women, spouse and the networked hospital through the application
                            </p>
                            <p className='text-justify'>
                            Further, Savemom also provides AlloTricorder and AlloWear, patient wellbeing products as part of the value added services. AlloTricorder – one device providing 6 vitals screening. 
                            </p>
                            <p className='text-justify'>
                            AlloWear – Available as bracelet / necklace type which monitors the physical activity and sleep cycle of the pregnant women. Both AlloTricorder and AlloWear transfers the data using Bluetooth. 
                            </p>
                            <p className='text-justify'>
                            AlloBaby application grades the patient risk score using AI and doctors could take informed decision.
                            </p>
                            <p className='text-justify'>
                            Allobot is an interactive application in built in AlloBaby, interacts with the pregnant woman to know the  mood for the day, glasses of water consumed, alerts/ reminders for medicine.
                            </p>
                            <p className='text-justify'>
                            Pregnant woman can save the health record documents, prescription, scan reports in the AlloBaby application. 
                            </p>
                            <p className='text-justify'>
                            The hospitals can broadcast the general pregnancy awareness information, doctor’s availability, scan remainders, any camps, schemes etc through the AlloBaby application.
                            </p>
                            <p className='text-justify'>
                            AlloBaby has a chat portal, a closed group of pregnant women networked with the hospital, can share their query, and discuss freely with the group. 
                            </p>
                            <p className='text-justify'>
                            The doctors, medical team at the networked hospital monitors the pregnant women health and can call in for any additional consultation, admission required for high risk patients. 
                            </p>
                            <p className='text-justify'>
                            Pregnant woman can fix virtual / direct consultations, video consultations through AlloBaby. 
                            </p>
                            <p className='text-justify'>
                            AlloBaby available in three different subscription packages namely Silver, Gold and Platinum. 
                            </p>
                            <p className='text-justify'>
                            All new users can use AlloBaby free for 30 days, and can choose their subscription plan.
                            </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
    </div>
  )
}

export default SelfScreening