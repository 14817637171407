import React from 'react';
import '../../src/App.css';
import Container from 'react-bootstrap/Container';

function FooterSection() {
  return (
    <div className='footer_main'>
     <div className='col-lg-12'>
        <div className='d-flex h-100'>
        <footer className="footer justify-content-center align-self-center">
        <div className="container">
        <div className="row">
        <div className="col-lg-12 col-sm-12 col-12 mb-lg-4 mb-sm-4 mb-3">
        <img alt="Allobaby" src={"assets/Allobaby_Logo.svg"} />
        </div>
        <div className="col-lg-4 col-sm-4 col-12">
        <p></p>
        <ul>
        <li><a href="#">HOME</a></li>
        <li><a href="#">ABOUT US</a></li>
        <li><a href="#">OUR OFFERINGS </a></li>
        <li><a href="#">CONTACT US</a></li>
        <li><a href="#">OTHERS</a></li>
        </ul>
        </div>
        <div className="col-lg-4 col-sm-4 col-12">
        <p>PHONE NUMBER </p>
        <ul>
        <li><a href="#">+918095207092</a></li>
        <li><a href="#">+918015005005</a></li>
        <li><a href="#">+91 7667878400</a></li>
        </ul>
        </div>
        <div className="col-lg-4 col-sm-4 col-12">
        <p>EMAIL ID</p>
        <ul className="">
        <li><a href="#">hello@jiovio.com</a></li>
        <li><a href="#">hr@jiovio.com</a></li>
        </ul>
        <ul className="social-icon">
        <li>
            <a href="https://www.linkedin.com/company/jiovio?originalSubdomain=in"><img alt='social' src='assets/socialicon/Icon_01.svg' /></a>
        </li>
        <li>
            <a href="https://www.instagram.com/jiovio_healthcare/"><img alt='social' src='assets/socialicon/Icon_02.svg' /></a>
        </li>
        <li>
            <a href="https://www.facebook.com/jioviohealthcare/"><img alt='social' src='assets/socialicon/Icon_03.svg' /></a>
        </li>
        <li>
            <a href="https://twitter.com/jio_vio"><img alt='social' src='assets/socialicon/Icon_04.svg' /></a>
        </li>
        <li>
            <a href="https://www.youtube.com/channel/UCQHoHxfqc62-pAv9a4XTcJA/featured"><img alt='social' src='assets/socialicon/Icon_05.svg' /></a>
        </li>
        </ul>
        </div>
        <div className="col-lg-12 col-sm-12 col-12">
        <div className="text-center">
        <p>© 2022. Redant Media . All Rights Reserved. Terms Privacy</p>
        </div>
        </div>
        </div>
        </div>
        </footer>
        </div>
      </div>
    </div>
  )
}

export default FooterSection;