import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import { useEffect, useState, useRef } from 'react';
import { FaCheck } from 'react-icons/fa';
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";



//import covervid from 'file_example_MP4_480_1_5MG.mp4';
//import covervidtwo from './hw.ogv';

function OurApp(){

    const [showcontent, setContent] = useState(1);

    const handleDiv = (e) =>{
        setContent(e);
    }

    useEffect(()=>{

      let unmounted = false;
      let invrl = setInterval(()=>{
         if(!unmounted){
setContent(curnt=>curnt%7+1);
         }
      }, 5000);
      return()=>{
         unmounted = true;
         clearInterval(invrl);
      }
    },[])

    const testimonial = [
        {
        title : "Piya",
        img :"assets/home/Image_01.png",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.",
        author: "Sarah M., Director of Events",
        },
        {
        title : "Gows",
        img :"assets/home/Image_01.png",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.",
        author: "Sarah M., Director of Events",
        },
        {
        title : "Siva Aruna",
        img :"assets/home/Image_01.png",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.",
        author: "Sarah M., Director of Events",
        },
        {
        title : "Jothi Priya",
        img :"assets/home/Image_01.png",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.",
        author: "Sarah M., Director of Events",
        }
    ];
/* Video player */

/* Video player */

    return(
         <div className="col-lg-12 p-0 allobaby_ourapp">
         <div className="main-slider position-relative">
         <div className="rt_line">
          <img alt="" src="assets/home/Element_17.webp" />
         </div>
         {/* <div className="lt_line">
         <img alt="" src="assets/home/Element_18.webp" />
         </div> */}
         <div className="social_icons">
           <ul>
              <li><a href="https://www.linkedin.com/company/jiovio?originalSubdomain=in"> <img alt="" src="assets/home/Icon_01.svg" /></a></li>
              <li><a href="https://www.instagram.com/jiovio_healthcare/"> <img alt="" src="assets/home/Icon_02.svg" /></a></li>
              <li><a href="https://www.facebook.com/jioviohealthcare/"> <img alt="" src="assets/home/Icon_03.svg" /></a></li>
              <li><a href="https://twitter.com/jio_vio"> <img alt="" src="assets/home/Icon_04.svg" /></a></li>
              <li><a href="https://www.youtube.com/channel/UCQHoHxfqc62-pAv9a4XTcJA/featured"> <img alt="" src="assets/home/Icon_05.svg" /></a></li>
           </ul>
        </div>
        <Carousel className="position-relative" fade interval={6000}>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-100.webp"}
                 alt="First slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum 2</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-101.webp"}
                 alt="Second slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum 3</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-102.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-100"
                 src={"assets/home/banner/Group-103.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-104"
                 src={"assets/home/banner/Group-104.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
           <Carousel.Item>
              <img
                 className="d-block w-104"
                 src={"assets/home/banner/Group-105.webp"}
                 alt="Third slide"
                 />
              {/* <Carousel.Caption>
                 <h3>Lorem ipsum</h3>
                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </Carousel.Caption> */}
           </Carousel.Item>
        </Carousel>
    {/* <div className="btm_img_line">
    <img
    className="d-block w-100"
    src={"assets/home/img_01.webp"}
    alt="Third slide"
    />
    </div> */}
     </div>
<div className="section app-feature d-flex h-100">
<div className="right_side_img">
    <img alt="App Features" className="parent_img" src="assets/home/Element_09.svg" />
    <div className="grid_round" id="tab" >
    <span><img alt="App Features" onClick={()=>handleDiv(1)} className= {showcontent===1? "nav-link child_img child1 active": "nav-link child_img child1"} src="assets/home/Icon_06.svg" /></span>
    <span><img alt="App Features" onClick={()=>handleDiv(2)} className= {showcontent===2? "nav-link child_img child2 active": "nav-link child_img child2"} src="assets/home/Icon_07.svg" /></span>
    <span><img alt="App Features" onClick={()=>handleDiv(3)} className= {showcontent===3? "nav-link child_img child3 active": "nav-link child_img child3"} src="assets/home/Icon_08.svg" /></span>
    <span><img alt="App Features" onClick={()=>handleDiv(4)} className= {showcontent===4? "nav-link child_img child4 active": "nav-link child_img child4"} src="assets/home/Icon_09.svg" /></span>
    <span><img alt="App Features" onClick={()=>handleDiv(5)} className= {showcontent===5? "nav-link child_img child5 active": "nav-link child_img child5"} src="assets/home/Icon_10.svg" /></span>
    <span><img alt="App Features" onClick={()=>handleDiv(6)} className= {showcontent===6? "nav-link child_img child6 active": "nav-link child_img child6"} src="assets/home/Icon_11.svg" /></span>
    <span><img alt="App Features" onClick={()=>handleDiv(7)} className= {showcontent===7? "nav-link child_img child7 active": "nav-link child_img child7"} src="assets/home/Icon_12.svg" /></span>
    </div>
   </div>
   <div className="container justify-content-center align-self-center">
   <div className="lt_line_common">
    <img alt="" src="assets/home/Element_18.webp" />
   </div>
   <div className="row">
       <div className="col-lg-6 col-sm-6 col-12">
         <div className="grid-content tab-content" id="tabcontent">
            <h3 className="mb-lg-4 mb-sm-4 mb-2"><span>APP</span> FEATURES</h3>
            <div className={showcontent===1? "tab-pane fade show active d-lg-block d-sm-block d-none" : "tab-pane fade show"}>
            <h5>Self-Screening </h5>
            <p>AlloBaby has list of symptoms that are commonly associated with pregnancy, if the pregnant women are having any of the symptom, they can select the same. They can select normal if they don’t have any symptom and doing well. <Link to="/selfscreening" className="read_more">Read more</Link></p>
            </div>
            <div className={showcontent===2? "tab-pane fade show active" : "tab-pane fade show"}>
            <h5>AI Driven Early Risk Identification</h5>
            <p>Remotely monitor all patients with unique workflows based on patient's clinical and social risk, to tailor prenatal and postpartum care to individual patient needs.</p>
           </div>
           <div className={showcontent===3? "tab-pane fade show active" : "tab-pane fade show"}>
           <h5>EMR Integration  </h5>
           <p>Enable deeper coordination between Allobaby and the EMR with our set of EMR-integrated standard and advanced workflows, allowing you to do what you need to in the fewest clicks possible.</p>
           
           </div>
           <div className={showcontent===4? "tab-pane fade show active" : "tab-pane fade show"}>
           <h5>Appointment booking</h5>
           <p>Allobaby can detect elevated risk in between prenatal and postpartum appointments, and will alert your doctor, if necessary.</p>
           
           </div>
           <div className={showcontent===5? "tab-pane fade show active" : "tab-pane fade show"}>
           <h5>Awareness on Pregnancy</h5>
           <p>Intelligent end-to-end virtual care program to manage your maternal population remotely. Streamline your members' journey through prenatal and postpartum with a custom mobile app featuring daily educational content. Push out timely reminders and urgent information to shape better behaviors and engage members on a routine basis. </p>
           
           </div>
           <div className={showcontent===6? "tab-pane fade show active" : "tab-pane fade show"}>
           <h5>Interactive AlloBoT</h5>
           <p>Leverage our modules to remotely monitor members and bridge access to care issues. Enable providers to virtually manage pregnant members across risk strata through maternal health monitoring. Enable real-time data collection and exception-based monitoring for blood pressure and mental health.</p>
           
           </div>
           <div className={showcontent===7? "tab-pane fade show active" : "tab-pane fade show"}>
           <h5>Virtual Teleconsultation </h5>
           <p>Enable providers to virtually manage pregnant members across risk strata through maternal health monitoring. Enable real-time data collection and exception-based monitoring for blood pressure and mental health. </p>
           
           </div>
          </div>
        </div>
       <div className="col-lg-6 col-sm-6 col-12">
      </div>
      <div className="col-lg-12 col-sm-12 col-12 d-lg-none d-sm-none d-block">
      <Card className="mb-3">
      <Card.Body>
      <h5>Self-Screening </h5>
      <p>AlloBaby has list of symptoms that are commonly associated with pregnancy, if the pregnant women are having any of the symptom, they can select the same. They can select normal if they don’t have any symptom and doing well. </p>
      <p className="text-right"><Link to="/selfscreening" className="read_more">Read more</Link></p>
      </Card.Body>
      </Card>
      <Card className="mb-3">
      <Card.Body>
      <h5>AI Driven Early Risk Identification</h5>
      <p>Remotely monitor all patients with unique workflows based on patient's clinical and social risk, to tailor prenatal and postpartum care to individual patient needs. </p>
      {/* <a href="#" className="read_more"> read more.</a> */}
      </Card.Body>
      </Card>
      <Card className="mb-3">
      <Card.Body>
      <h5>EMR Integration  </h5>
      <p>Enable deeper coordination between Allobaby and the EMR with our set of EMR-integrated standard and advanced workflows, allowing you to do what you need to in the fewest clicks possible.</p>
      </Card.Body>
      </Card>
      <Card className="mb-3">
      <Card.Body>
      <h5>Appointment booking</h5>
      <p>Allobaby can detect elevated risk in between prenatal and postpartum appointments, and will alert your doctor, if necessary.</p>
       </Card.Body>
      </Card>
      <Card className="mb-3">
      <Card.Body>
      <h5>Awareness on Pregnancy</h5>
      <p>Intelligent end-to-end virtual care program to manage your maternal population remotely. Streamline your members' journey through prenatal and postpartum with a custom mobile app featuring daily educational content. Push out timely reminders and urgent information to shape better behaviors and engage members on a routine basis. </p>
      </Card.Body>
      </Card>
      <Card className="mb-3">
      <Card.Body>
      <h5>Interactive AlloBoT</h5>
      <p>Leverage our modules to remotely monitor members and bridge access to care issues. Enable providers to virtually manage pregnant members across risk strata through maternal health monitoring. Enable real-time data collection and exception-based monitoring for blood pressure and mental health.</p>
      </Card.Body>
      </Card>
      <Card className="mb-3">
      <Card.Body>
      <h5>Virtual Teleconsultation</h5>
      <p>Enable providers to virtually manage pregnant members across risk strata through maternal health monitoring. Enable real-time data collection and exception-based monitoring for blood pressure and mental health. </p>
      </Card.Body>
      </Card>
      </div>
    </div>
   </div>
</div>
<div className="section app-feature d-flex h-100 pt-lg-5 pt-sm-5 pt-3 pb-lg-5 pb-sm-5 pb-3">
   <div className="lt_line_common choose_lineplane">
     <img alt="" src="assets/home/Element_18.webp" />
   </div>
   <div className="container justify-content-center align-self-center">
     <div className="row">
       <div className="col-lg-12 col-sm-12 col-12">
         <div className="grid-content">
            <h3 className="mb-lg-4 mb-sm-4 mb-2">CHOOSE YOUR PLAN</h3>
            <div className="row">
            <div className="col-lg-12 col-sm-12 col-12 our-app-table mb-lg-4 mb-sm-4 mb-3">
            <Table bordered className="text-center">
            <thead>
            <tr>
            <th>TYPE OF MEMBERSHIP</th>
            <th>SILVER</th>
            <th>GOLD</th>
            <th>PLATINUM</th>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td className="border_btm">Patient Health Record</td>
            <td>User access for 
BasicHealth Record</td>
            <td>User access for 
Automated Health Record</td>
            <td>User access for
 Automated Health Record</td>
            </tr>
            <tr>
            <td className="border_top" colSpan={1}></td>
            <td>Manual Data Entry by
 Pregnant Women in
 the Application</td>
            <td>Automatic - Bluetooth 
Data Entry</td>
            <td>Automatic - Bluetooth 
Data Entry</td>
            </tr>
            <tr>
            <td>AI Enabled Risk Categorization</td>
            <td>Available</td>
            <td>Available</td>
            <td>Available</td>
            </tr>
            <tr>
            <td>EMR Storage</td>
            <td>Pregnant Women health data stored in the Application</td>
            <td>Pregnant Women and Child health data stored in the Application</td>
            <td>Pregnant Women and Child health data stored in the Application</td>
            </tr>
            <tr>
            <td className="border_btm">AlloBot Interactive Application</td>
            <td>N/A</td>
            <td>Interactive questions asked by the app regarding Mood, Sleep, Hydration</td>
            <td>Interactive questions asked by the app regarding Mood, Sleep, Hydration</td>
            </tr>
            <tr>
            <td className="border_top" colSpan={1}></td>
            <td></td>
            <td>Timely alerts for taking medication</td>
            <td>Timely alerts for taking medication</td>
            </tr>
            <tr>
            <td className="boder_btm">AlloBot Interactive Application</td>
            <td>Health Monitoring by your Hospital medical team</td>
            <td>Health Monitoring by your Hospital medical team</td>
            <td>Health Monitoring by your Hospital medical team</td>
            </tr>
            <tr>
            <td className="border_top boder_btm" colSpan={1}></td>
            <td>N/A</td>
            <td>Telemedicine</td>
            <td>Telemedicine</td>
            </tr>
            <tr>
            <td className="border_top boder_btm" colSpan={1}></td>
            <td>N/A</td>
            <td>Virtual consutation</td>
            <td>Virtual consutation</td>
            </tr>
            <tr>
            <td>Information / Communication from Hospital</td>
            <td>N/A</td>
            <td>Broadcast messages regarding Camps, offers, other information</td>
            <td>Broadcast messages regarding Camps, offers, other information</td>
            </tr>
            <tr>
            <td colSpan={1}></td>
            <td></td>
            <td>General Awareness Information from Hospital</td>
            <td>General Awareness Information from Hospital</td>
            </tr>
            </tbody>
            </Table>
            </div>
            <div className="col-lg-12 col-sm-12 col-12 our-app-table mb-lg-4 mb-sm-4 mb-3">
            <h4 className="text-center h4_title">VALUE ADDED SERVICES</h4>
            <h5 className="text-center h5_title">PATIENT WELL BEING GAGETS</h5>
            <Table striped bordered hover className="text-center">
            <thead>
            <tr>
            <th>TYPE OF MEMBERSHIP</th>
            <th>SILVER</th>
            <th>GOLD</th>
            <th>PLATINUM</th>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td>Allowear*</td>
            <td>N/A</td>
            <td>Monitors Sleep, Activities, Reminders</td>
            <td>Monitors Sleep, Activities, Reminders</td>
            </tr>
            <tr>
            <td>AlloTricorder*</td>
            <td>N/A</td>
            <td>Monitors Sleep, Activities, Reminders</td>
            <td>Monitors Sleep, Activities, Reminders</td>
            </tr>
            <tr>
            <td colSpan={2}></td>
            <td>N/A</td>
            <td>BP, HR, SPO2, RR, Temperature, ECG, Blood Glucose</td>
            </tr>
            <tr>
            <td colSpan={2}></td>
            <td></td>
            <td>Captured Data transferred automatically to the mobile application using bluetooth</td>
            </tr>
            </tbody>
            </Table>
            </div>
            <div className="col-lg-12 col-sm-12 col-12 our-app-table">
            <Table striped bordered hover className="text-center"> 
            <thead>
            <tr>
            <th>PACKAGES</th>
            <th>SILVER</th>
            <th>GOLD</th>
            <th>PLATINUM</th>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td>First Month</td>
            <td><span className="green">FREE</span></td>
            <td><span className="green">FREE</span></td>
            <td><span className="green">FREE</span></td>
            </tr>
            <tr>
            <td>Half yearly (6 Months)</td>
            <td>499</td>
            <td>899</td>
            <td>1199</td>
            </tr>
            <tr>
            <td>Annual Package (12 Months)</td>
            <td>499</td>
            <td>899</td>
            <td>1199</td>
            </tr>
            </tbody>
            </Table>
            <p style={{color:'#FF626F'}}>Note: AlloWear and AlloTricorder shall be purchased separately.</p>
            </div>
           </div>
         </div>
       </div>
    </div>
   </div>
   </div>
 <div className="section app-feature d-flex h-100 pt-lg-5 pt-sm-5 pt-3 pb-lg-5 pb-sm-5 pb-3">
   <div className="lt_line_common">
     <img alt="" src="assets/home/Element_18.webp" />
   </div>
   <div className="container justify-content-center align-self-center">
     <div className="row">
       <div className="col-lg-12 col-sm-12 col-12">
         <div className="grid-content d-flex h-100">
            <div className="justify-content-center align-self-center">
            <h3 className="mb-lg-4 mb-sm-4 mb-2">DOWNLOAD <span className="border-stock">APP</span> HERE</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi .</p>
            <a href='#' className='btn btn-click-more'>CLICK HERE</a>
            </div>
         </div>
       </div>
    </div>
   </div>
</div>
</div>
    )
}

export default OurApp;